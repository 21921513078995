<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("general.informations") }}</h4>
					<p v-html="$t('templates.list.content')" />
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("template.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="$router.push({ name: 'Add template' })"
							v-if="hasPermission('provision:template_add')">
							{{ $t("template.add") }}
							<md-icon>add</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search')"
									v-model="filters.query">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								md-label="Type"
								md-sort-by="type"
								style="width: 5%; text-align: center"
								class="centered-content bordered-cell">
								<template v-if="item.customer_id === 0">
									<span class="badge badge-primary">COMMUNITY<br />TEMPLATE</span>
								</template>

								<template v-if="item.customer_id != 0">
									<span class="badge badge-pill badge-warning">USER<br />TEMPLATE</span>
								</template>
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.name.label')"
								md-sort-by="name"
								style="width: 15%"
								class="centered-content bordered-cell">
								{{ item.name }}
							</md-table-cell>

							<md-table-cell
								:md-label="$t('fields.description.label')"
								md-sort-by="description"
								style="width: 30%; white-space: pre-line"
								class="centered-content bordered-cell"
								>{{ item.description }}</md-table-cell
							>

							<md-table-cell
								:md-label="$t('fields.from_router.label')"
								md-sort-by="from_version"
								style="width: 10%; text-align: center"
								class="centered-content bordered-cell"
								>{{ item.from_version }}</md-table-cell
							>

							<md-table-cell
								:md-label="$t('fields.to_router.label')"
								md-sort-by="to_version"
								style="width: 10%; text-align: center"
								class="centered-content bordered-cell"
								>{{ item.to_version }}</md-table-cell
							>

							<md-table-cell
								:md-label="$t('fields.update_date.label')"
								md-sort-by="update_at"
								style="width: 10%; text-align: center"
								class="centered-content bordered-cell"
								>{{ item.update_at }}</md-table-cell
							>

							<md-table-cell
								:md-label="$t('fields.creation_date.label')"
								md-sort-by="insert_at"
								style="width: 10%; text-align: center"
								class="centered-content bordered-cell"
								>{{ item.insert_at }}</md-table-cell
							>

							<md-table-cell :md-label="$t('fields.actions.label')" style="width: 10%">
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-info"
									@click.native="showTemplateTextModal(item.customer_id, item.template_id)"
									v-if="hasPermission('provision:template_preview')">
									<md-tooltip md-direction="top"
										><p v-html="$t('template.showTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>search</md-icon>
										<div style="font-size: xx-small">PREVIEW</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-warning"
									@click.native="editTemplateDirectly(item.template_id)"
									v-if="item.customer_id !== 0 && hasPermission('provision:template_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('template.editTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>edit</md-icon>
										<div style="font-size: xx-small">Edit</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-danger"
									@click.native="handleDelete(item)"
									v-if="item.customer_id !== 0 && hasPermission('provision:template_delete')">
									<md-tooltip md-direction="top"
										><p v-html="$t('template.deleteTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">Delete</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
			<modal v-if="flgShowTemplateTextModal" @close="hideTemplateTextModal">
				<template slot="header">
					<h4 class="modal-title">{{ templateModal.title }}</h4>
					<md-button
						class="md-simple md-just-icon md-round modal-default-button"
						@click="hideTemplateTextModal">
						<md-icon>clear</md-icon>
					</md-button>
				</template>

				<template slot="body">
					<div style="white-space: pre-line; text-align: left">
						<p>{{ templateModal.description }}</p>
					</div>
					<div class="copy-paste-box">{{ templateModal.text }}</div>
				</template>
			</modal>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import { Modal } from "@/components";
import evoapi from "@/services/evoapi";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
		Modal,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			flgShowTemplateTextModal: false,
			templateModal: {
				title: "",
				desciption: "",
				text: "",
				from_version: "",
				to_version: "",
				template_id: 0,
			},
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: ["Id", this.$t("fields.name.label")],
			searchQuery: "",
			propsToSearch: ["name", "type", "description"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		showTemplateTextModal(customer_id, template_id) {
			// Get data from API
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + customer_id + "/templates/" + template_id).then((response) => {
				this.templateModal.template_id = response.data.template_id;
				this.templateModal.title = response.data.name;
				this.templateModal.description = response.data.description;
				this.templateModal.text = response.data.text;
				this.templateModal.from_version = response.data.from_version;
				this.templateModal.to_version = response.data.to_version;
				this.flgShowTemplateTextModal = true;
			});
		},
		hideTemplateTextModal() {
			this.flgShowTemplateTextModal = false;
		},
		addTemplateRequest() {
			const { value: deviceName } = Swal.fire({
				title: this.$t("dialogs.add_device_from_template.title"),
				input: "text",
				inputPlaceholder: this.$t("dialogs.add_device_from_template.placeholder"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.add_device_from_template.write_name");
					}
				},
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.add_device_from_template.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					let userData = TokenService.getUser();
					evoapi
						.post("/customers/" + userData.customerId + "/devices", {
							name: result.value,
						})
						.then((response) => {
							let commands = response.data.join("<br><br>");
							Swal.fire({
								title: this.$t("dialogs.add_device_from_template_finish.title"),
								html: this.$t("dialogs.add_device_from_template_finish.content"),
								buttonsStyling: false,
								confirmButtonClass: "md-button md-success",
								icon: "success",
							});
							evoapi.get("/customers/" + userData.customerId + "/devices").then((updateData) => {
								this.tableData = updateData.data;
							});
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							} else {
								// console.log(error);
							}
						});
				}
			});
		},
		editTemplate() {
			this.$router.push({
				name: "Edit template",
				params: { template: this.templateModal },
			});
		},
		editTemplateDirectly(template_id) {
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + userData.customerId + "/templates/" + template_id).then((response) => {
				this.templateModal.template_id = response.data.template_id;
				this.templateModal.title = response.data.name;
				this.templateModal.description = response.data.description;
				this.templateModal.text = response.data.text;
				this.templateModal.from_version = response.data.from_version;
				this.templateModal.to_version = response.data.to_version;
				this.flgShowTemplateTextModal = true;
				this.$router.push({
					name: "Edit template",
					params: { template: this.templateModal },
				});
			});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleEdit(item) {
			Swal.fire({
				title: this.$t("dialogs.edit_template.title", { subject: item.name }),
				buttonsStyling: false,
				confirmButtonClass: "md-button md-info",
			});
		},
		handleDelete(item) {
			Swal.fire({
				title: this.$t("dialogs.delete_template.title"),
				text: this.$t("dialogs.delete_template.content", { subject: item.name }),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.delete_template.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					let userData = TokenService.getUser();
					evoapi
						.delete("/customers/" + userData.customerId + "/templates/" + item.template_id)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							Swal.fire({
								title: this.$t("dialogs.delete_template_finish.title"),
								text: this.$t("dialogs.delete_template_finish.content", { subject: item.name }),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							}).then(() => {
								location.reload();
							});
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							} else {
								// console.log(error);
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["name"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + userData.customerId + "/templates").then((response) => {
				this.tableData = response.data;
				this.searchedData = this.tableData;
			});
		},
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				//console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.modal-code {
	text-align: left;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
	overflow: scroll;
	height: 500px;
}

.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
	white-space: pre-line;
	text-align: left;
	overflow: auto;
	max-height: 600px;
}
</style>
