<!-- questo è la selezione del template per i device onboardati tramite un provisioner -->
<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text">
					{{ $t("wizard_provisioning.step_3_new.title") }}
				</h5>
				<p class="info-text">
					{{ $t("wizard_provisioning.step_3_new.content") }}
				</p>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<md-field>
							<label for="template_id">Templates</label>
							<md-select v-model="template_id" name="template_id" id="template_id">
								<md-option value="0"
									><b>{{ $t("wizard_provisioning.step_3_new.no_templates_found") }}</b></md-option
								>
								<md-option
									v-for="(item, idx) in templates"
									v-bind:key="idx"
									:value="item.template_id"
									>{{ item.name }}</md-option
								>
							</md-select>
						</md-field>
					</div>
				</div>
				<h5 class="info-text" v-if="flgShowTemplateVariables">
					{{ $t("wizard_provisioning.step_3_new.title_2") }}
				</h5>
				<div class="md-layout" v-if="flgShowTemplateVariables">
					<div
						class="md-layout-item md-size-100 md-small-size-100"
						v-for="(item, idx) in template.variables"
						v-bind:key="idx">
						<ValidationProvider
							:name="item"
							rules="required"
							v-slot="{ passed, failed }"
							v-if="item != '<<SYS_ProvisioningCounter>>'">
							<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
								<label>{{ item }}</label>
								<md-input v-model="variables[item]" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
					</div>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { compare } from "compare-versions";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			template_id: 0,
			templates: [],
			flgShowTemplateVariables: false,
			template: null,
			variables: [],
			deviceOsVersion: "",
		};
	},
	watch: {
		template_id: function () {
			if (this.template_id > 0) {
				let userData = TokenService.getUser();
				evoapi.get("/customers/" + userData.customerId + "/templates/" + this.template_id).then((response) => {
					this.template = response.data;
					this.flgShowTemplateVariables =
						this.template.variables != null && this.template.variables.length > 0;
					if (
						this.deviceOsVersion != "" &&
						!(
							compare(this.template.from_version, this.deviceOsVersion, "<=") &
							compare(this.template.to_version, this.deviceOsVersion, ">=")
						)
					) {
						this.notifyError("top", "center");
					}
				});
			} else {
				this.flgShowTemplateVariables = false;
			}
		},
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
		notifyError(verticalAlign, horizontalAlign) {
			this.$notify({
				timeout: 5000,
				message: this.$t("wizard_provisioning.step_3_new.response_error"),
				icon: "add_alert",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: "warning",
			});
		},
	},
	mounted() {
		// Get data from API
		let userData = TokenService.getUser();
		evoapi.get("/customers/" + userData.customerId + "/templates").then((response) => {
			this.templates = response.data;
		});
	},
};
</script>
<style></style>
