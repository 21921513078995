// store/monitoringLogin.js
// Questo store contiene le informazioni relative all'accesso al servizio di monitoraggio SDWAN
// è utilizzato in SdwanDetailsContent.vue
function getInitialState() {
	return {
		lastLoginTime: null,
		loginRedirectUrl: null,
	};
}

export const monitoringLogin = {
	namespaced: true,
	state: getInitialState(),
	mutations: {
		setLoginTime(state, time) {
			state.lastLoginTime = time;
		},
		setLoginRedirectUrl(state, url) {
			state.loginRedirectUrl = url;
		},
		resetState(state) {
			Object.assign(state, getInitialState());
		},
	},
	actions: {
		updateLoginTime({ commit }) {
			const currentTime = new Date().getTime();
			commit("setLoginTime", currentTime);
		},
		updateLoginRedirectUrl({ commit }, url) {
			commit("setLoginRedirectUrl", url);
		},
		resetLoginState({ commit }) {
			commit("resetState");
		},
	},
	getters: {
		isLoginValid: (state) => {
			if (!state.lastLoginTime) return false;
			const currentTime = new Date().getTime();
			return currentTime - state.lastLoginTime < 1 * 60 * 1000; // 1 minuti in millisecondi
		},
		loginRedirectUrl: (state) => state.loginRedirectUrl,
	},
};
