<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(submit)">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<div class="card-text">
								<h4 class="title">{{ $t("deviceGroup.edit") }}</h4>
							</div>
						</md-card-header>

						<md-card-content>
							<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("deviceGroup.name") }}</label>
									<md-input v-model="deviceGroup.name"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>

							<ValidationProvider name="default_pwd" v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("deviceGroup.password") }}</label>
									<md-input v-model="deviceGroup.default_pwd"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>
						</md-card-content>

						<md-card-actions>
							<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
						</md-card-actions>
					</md-card>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			deviceGroup: {
				name: "",
				default_pwd: "",
			},
		};
	},
	created() {
		this.deviceGroup = this.$route.params.deviceGroup;
	},
	methods: {
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.put("/customers/" + userData.customerId + "/device-groups/" + this.deviceGroup.device_group_id, {
					name: this.deviceGroup.name,
					default_pwd: this.deviceGroup.default_pwd,
				})
				.then((response) => {
					this.$router.push({ name: "List device groups" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Qualcosa è andato storto!",
							footer: "Riprova e se il problema persiste, contatta l'assistenza",
						});
					}
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
	border: none;
}
</style>
