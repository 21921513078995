var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("device.editDevice")))])])]),_c('md-card-content',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|regex:^[a-zA-Z0-9-_. ]*$"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('md-field',{class:[
									{ 'md-error': failed },
									{ 'md-valid': passed },
									{ 'md-form-group': true },
								]},[_c('md-icon',[_vm._v("badge")]),_c('label',[_vm._v(_vm._s(_vm.$t("fields.device_name.label")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.device.name),callback:function ($$v) {_vm.$set(_vm.device, "name", $$v)},expression:"device.name"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error-message"},[_vm._v(_vm._s(_vm.$t("fields.device_name.validation")))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"device_groups"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed }){return [_c('md-field',{class:[
									{ 'md-error': failed },
									{ 'md-valid': passed },
									{ 'md-form-group': true },
								]},[_c('md-icon',[_vm._v("group")]),_c('label',[_vm._v(_vm._s(_vm.$t("fields.device_group.label")))]),_c('md-select',{attrs:{"name":"deviceGroups","id":"deviceGroups","multiple":""},model:{value:(_vm.device.device_groups),callback:function ($$v) {_vm.$set(_vm.device, "device_groups", $$v)},expression:"device.device_groups"}},_vm._l((_vm.listDeviceGroups),function(item){return _c('md-option',{key:item.device_group_id,attrs:{"value":item.device_group_id}},[_vm._v(_vm._s(item.name))])}),1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}],null,true)})],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-success",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("device.updateDevice")))])],1)],1)],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }