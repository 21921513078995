<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text">
					{{ $t("wizard_sdwan.step_2.title") }}
				</h5>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>description</md-icon>
								<label>{{ $t("fields.description.label") }}</label>
								<md-input v-model="description" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
					</div>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { integer } from "vee-validate/dist/rules";

extend("required", required);
extend("integer", integer);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			description: "",
		};
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
	},
};
</script>
<style></style>
