<template>
	<div class="md-layout-item md-size-100">
		<md-card>
			<md-card-content v-html="$t('monitoring.info')"></md-card-content>
		</md-card>
		<iframe ref="redirectFrame" style="display: none; width: 100%; height: auto; border: 0px"></iframe>
		<md-card>
			<md-card-header>
				<h4 class="title">{{ $t("monitoring.title") }}</h4>
				<p class="category">{{ $t("monitoring.system_message") }}</p>
			</md-card-header>
			<md-card-content v-html="$t('monitoring.description')"> </md-card-content>
		</md-card>
	</div>
</template>
<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	mounted() {
		let userData = TokenService.getUser();
		evoapi.post("/customers/" + userData.customerId + "/monitoring/login").then((response) => {
			this.$refs.redirectFrame.setAttribute("src", response.data.redirectUrl);
			this.$refs.redirectFrame.onload = () => {
				this.$refs.redirectFrame.contentWindow.document.body.style.margin = "0px";
				// setting height of iframe to the height of the page
				this.$refs.redirectFrame.style.height = document.body.scrollHeight + "px";
			};
			// making iframe visible
			this.$refs.redirectFrame.style.display = "block";
			// location.href = response.data.redirectUrl
		});
	},
};
</script>
