// =========================================================
// * Vue Material Dashboard PRO - v1.5.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import VueI18n from "vue-i18n";
import i18n from "./i18n";

// router setup
import routes from "./routes/routes";

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);

// Fix select bug
import { MdField } from "vue-material/dist/components";

import store from "./store";
import setupInterceptors from "./services/interceptors";
setupInterceptors(store);

// configure router
const router = new VueRouter({
	routes, // short for routes: routes
	scrollBehavior: (to) => {
		if (to.hash) {
			return { selector: to.hash };
		} else {
			return { x: 0, y: 0 };
		}
	},
	linkExactActiveClass: "nav-item active",
});
router.beforeEach((to, from, next) => {
	let auth = store.state.auth;
	// Array di percorsi pubblici che non richiedono autenticazione
	const publicPages = ["/login", "/forgot-password", "/reset-password"];
	const authRequired = !publicPages.includes(to.path);

	if (authRequired && !auth.status.loggedIn) {
		next({ name: "Login" });
	} else {
		next();
	}
});

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
new Vue({
	el: "#app",
	render: (h) => h(App),
	router,
	store,
	i18n,
	created() {
		this.$store.dispatch("auth/initialize"); // Inizializza lo store ricaricando i dati dal localStorage
	},
});

Vue.use(MdField);
Vue.use(VueI18n);

Vue.component(
	"MdSelect",
	Vue.options.components.MdSelect.extend({
		methods: {
			isInvalidValue: function isInvalidValue() {
				return this.$el.validity ? this.$el.validity.badInput : true;
			},
		},
	})
);
