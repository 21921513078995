<template>
	<div class="routeros-version-slider">
		<div class="md-layout md-gutter">
			<div class="md-layout-item md-size-50">
				<div class="slider-container">
					<div class="slider-track"></div>
					<div
						class="slider-thumb"
						:style="{ left: `${leftThumbPosition}%` }"
						@mousedown="startDrag($event, 'left')"></div>
					<div
						class="slider-thumb"
						:style="{ left: `${rightThumbPosition}%` }"
						@mousedown="startDrag($event, 'right')"></div>
				</div>
			</div>
		</div>
		<div class="md-layout md-gutter">
			<div class="md-layout-item md-size-25">
				<md-field>
					<md-input
						v-model="minVersion"
						@input="updateSlider('min')"
						placeholder="Min (e.g. 6.48.4)"></md-input>
				</md-field>
			</div>
			<!-- <div class="md-layout-item md-size-10 md-alignment-center-center">
			-
			</div> -->
			<div class="md-layout-item md-size-25">
				<md-field>
					<md-input
						v-model="maxVersion"
						@input="updateSlider('max')"
						placeholder="Max (e.g. 7.12.1)"></md-input>
				</md-field>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RouterOSVersionSlider",
	data() {
		return {
			minVersion: "6.48.0",
			maxVersion: "7.15.0",
			dragging: null,
			minValue: 60000,
			maxValue: 99999,
		};
	},
	computed: {
		leftThumbPosition() {
			return ((this.versionToValue(this.minVersion) - this.minValue) / (this.maxValue - this.minValue)) * 100;
		},
		rightThumbPosition() {
			return ((this.versionToValue(this.maxVersion) - this.minValue) / (this.maxValue - this.minValue)) * 100;
		},
	},
	methods: {
		versionToValue(version) {
			const [major, minor, patch] = version.split(".").map(Number);
			return major * 10000 + minor * 100 + patch;
		},
		valueToVersion(value) {
			const major = Math.floor(value / 10000);
			const minor = Math.floor((value % 10000) / 100);
			const patch = value % 100;
			return `${major}.${minor}.${patch}`;
		},
		startDrag(event, thumb) {
			this.dragging = thumb;
			document.addEventListener("mousemove", this.onDrag);
			document.addEventListener("mouseup", this.stopDrag);
		},
		stopDrag() {
			this.dragging = null;
			document.removeEventListener("mousemove", this.onDrag);
			document.removeEventListener("mouseup", this.stopDrag);
		},
		onDrag(event) {
			if (!this.dragging) return;

			const sliderRect = event.target.closest(".slider-container").getBoundingClientRect();
			const percentage = (event.clientX - sliderRect.left) / sliderRect.width;
			const value = Math.round(this.minValue + percentage * (this.maxValue - this.minValue));

			if (this.dragging === "left") {
				this.minVersion = this.valueToVersion(Math.min(value, this.versionToValue(this.maxVersion)));
			} else {
				this.maxVersion = this.valueToVersion(Math.max(value, this.versionToValue(this.minVersion)));
			}

			this.$emit("update:range", [this.minVersion, this.maxVersion]);
		},
		updateSlider(type) {
			if (type === "min" && this.versionToValue(this.minVersion) > this.versionToValue(this.maxVersion)) {
				this.minVersion = this.maxVersion;
			} else if (type === "max" && this.versionToValue(this.maxVersion) < this.versionToValue(this.minVersion)) {
				this.maxVersion = this.minVersion;
			}
			this.$emit("update:range", [this.minVersion, this.maxVersion]);
		},
	},
};
</script>

<style scoped>
.routeros-version-slider {
	padding: 20px 0;
}
.slider-container {
	position: relative;
	height: 4px;
	background-color: #e0e0e0;
	margin: 20px 0;
}
.slider-track {
	position: absolute;
	height: 100%;
	background-color: #016aa6;
	left: 0;
	right: 0;
}
.slider-thumb {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #d02c92;
	border-radius: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}
</style>
