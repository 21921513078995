<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<template slot="header">
					<h3 class="title">{{ $t("device.add") }}</h3>
					<h5 class="category">
						{{ $t("device.letstart") }}
					</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label"> {{ $t("fields.name.label") }} </template>
					<first-step ref="step1" @on-validated="onStepValidated"></first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label"> Login & Reset </template>
					<second-step ref="step2" @on-validated="onStepValidated"></second-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step3')">
					<template slot="label"> Template </template>
					<third-step ref="step3" @on-validated="wizardComplete"></third-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from "./WizardSteps/FirstStep.vue";
import SecondStep from "./WizardSteps/SecondStep.vue";
import ThirdStep from "./WizardSteps/ThirdStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	data() {
		return {
			wizardModel: {},
		};
	},
	props: ["provisioner_id", "devices"],
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		SimpleWizard,
		WizardTab,
	},
	mounted() {
		if (this.devices.length == 1) {
			this.$refs.step3.deviceOsVersion = this.devices[0].version;
		}
	},
	methods: {
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		wizardComplete() {
			let variables = [];
			for (var index in this.$refs.step3.variables) {
				variables.push({
					variable_name: index,
					variable_value: this.$refs.step3.variables[index],
				});
			}

			let req = {
				name: this.$refs.step1.name,
				devices: this.devices,
				to_reset: this.$refs.step2.toReset,
				username: this.$refs.step2.username,
				password: this.$refs.step2.password,
				device_groups: this.$refs.step1.deviceGroups,
				device_group_password:
					this.$refs.step1.deviceGroupPassword > 0 ? this.$refs.step1.deviceGroupPassword : null,
			};
			if (this.$refs.step3.template_id > 0) {
				req.template = {
					template_id: this.$refs.step3.template_id,
					variables: variables,
				};
			}

			Swal.fire({
				title: this.$t("dialogs.device_provisioning_progress.title"),
				html: this.$t("dialogs.device_provisioning_progress.content"),
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});

			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/provisioners/" + this.provisioner_id + "/devices", req)
				.then((response) => {
					let password = response.data.password;
					Swal.close();
					if (response.data.password.length > 0) {
						Swal.fire({
							title: this.$t("dialogs.admin_password_reset.title"),
							// text: response.data.password,
							html: this.$t("dialogs.admin_password_reset.content", { password }),
							footer: this.$t("dialogs.reset_password_new.footer"),
							confirmButtonText: this.$t("dialogs.admin_password_reset.ok"),
							showCloseButton: false,
							icon: "success",
							allowOutsideClick: false,
							backdrop: `rgba(0,0,123,0.4)`,
						}).then((result) => {
							if (result.isConfirmed) {
								if (window.clipboardData && window.clipboardData.setData) {
									// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
									return window.clipboardData.setData("Text", password);
								} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
									var textarea = document.createElement("textarea");
									textarea.textContent = password;
									textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
									document.body.appendChild(textarea);
									textarea.select();
									try {
										return document.execCommand("copy"); // Security exception may be thrown by some browsers.
									} catch (ex) {
									} finally {
										document.body.removeChild(textarea);
									}
								}
							}
						});
					}
					this.$router.push({ name: "List Devices" });
				})
				.catch(function (error) {
					if (error.response) {
						let footer = this.$t("responses.error.footer");
						let stay = true;
						if (error.response.status == 409) {
							if (error.response.data.error_type == 1) {
								footer = this.$t("responses.provisioning_error.footer");
								stay = false;
							}
							if (error.response.data.password.length > 0) {
								footer += this.$t("responses.provisioning_error.footer_alt", {
									value: error.response.data.password,
								});
							}
						}
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: footer,
							customClass: {
								footer: "onboardingErrorFooter",
							},
						});
						if (!stay) {
							this.$router.push({ name: "List Devices" });
						}
					} else {
						// console.log(error);
					}
				});
		},
	},
};
</script>
<style>
.onboardingErrorFooter {
	flex-direction: column;
	align-items: center;
}
</style>
