<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("devices_backup.list.title") }}</h4>
					<p v-html="$t('backups.description')" />
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("backups.title") }}</h4>
					<br />
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search_records')"
									v-model="filters.query">
								</md-input>
							</md-field>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }" md-auto-select>
							<md-table-cell md-label="Id" md-sort-by="device_id" style="width: 5%">{{
								item.device_id
							}}</md-table-cell>
							<md-table-cell :md-label="$t('fields.name.label')" md-sort-by="name" style="width: 55%">{{
								item.name
							}}</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device_group.label')"
								md-sort-by="device_groups_text"
								style="width: 20%"
								>{{ item.device_groups_text }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.last_backup.label')"
								md-sort-by="last_backup"
								style="width: 55%">
								{{ item.last_backup ? $t("general.hours_ago", { value: item.last_backup }) : "n.d." }}
							</md-table-cell>
							<md-table-cell :md-label="$t('fields.actions.label')" style="width: 30%">
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-info"
									@click.native="showBackups(item.device_id)"
									v-if="hasPermission('backup:backup_show')"
									:disabled="!item.last_backup"
									:class="{ 'md-disabled': !item.last_backup }">
									<md-tooltip md-direction="top"
										><span v-html="$t('devices_backup.list.show_cta')"
									/></md-tooltip>
									<div class="icon-with-label">
										<md-icon>search</md-icon>
										<div style="font-size: xx-small">SHOW</div>
									</div>
								</md-button>
								<div></div>
								<div></div>
								<md-button
									class="md-just-icon md-simple md-success"
									@click.native="runBackup(item.device_id)"
									v-if="hasPermission('backup:backup_start')">
									<md-tooltip md-direction="top"
										><span v-html="$t('devices_backup.list.run_cta')"
									/></md-tooltip>
									<div class="icon-with-label">
										<md-icon>start</md-icon>
										<div style="font-size: xx-small">START</div>
									</div>
								</md-button>
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to: to, total: total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: [this.$t("fields.name.label"), this.$t("fields.device_groups.label")],
			searchQuery: "",
			propsToSearch: ["name", "device_groups_text"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			deviceGroups: [],
			filters: {
				query: "",
			},
		};
	},
	methods: {
		showBackups(device_id) {
			this.$router.push({ name: "Get device backups", params: { device_id: device_id } });
		},
		runBackup(device_id) {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/backups/devices/" + device_id + "/run")
				.then((response) => {
					Swal.fire({
						title: this.$t("responses.backup_success.title"),
						html: this.$t("responses.backup_success.content"),
						icon: "success",
						customClass: {
							confirmButton: "md-button md-success",
						},
						buttonsStyling: false,
					});
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["name", "device_groups"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			let userData = TokenService.getUser();

			// Fetch device groups first
			try {
				const groupsResponse = await evoapi.get("/customers/" + userData.customerId + "/device-groups");
				this.deviceGroups = groupsResponse.data;
			} catch (error) {
				// console.error("Error fetching device groups:", error);
			}

			// Fetch backup devices
			try {
				const response = await evoapi.get("/customers/" + userData.customerId + "/backups/devices");
				this.tableData = response.data;

				this.tableData.forEach((item) => {
					if (item.device_groups) {
						item.device_groups_text = item.device_groups
							.map((groupId) => {
								const group = this.deviceGroups.find((g) => g.device_group_id == groupId);
								return group ? group.name : groupId;
							})
							.join(", ");
					} else {
						item.device_groups_text = "";
					}
				});

				this.searchedData = this.tableData;
			} catch (error) {
				// console.error("Error fetching backup devices:", error);
			}
		},
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				// console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>
