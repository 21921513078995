<template>
	<div>
		<md-table v-model="users" md-card>
			<md-table-row slot="md-table-row" slot-scope="{ item }">
				<md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
				<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
				<md-table-cell md-label="Warning" md-sort-by="warning">{{ item.warning }}</md-table-cell>
				<md-table-cell md-label="Date" md-sort-by="date">{{ item.date }}</md-table-cell>
			</md-table-row>
		</md-table>
	</div>
</template>

<script>
export default {
	name: "global-sales-table",
	data() {
		return {
			selected: [],
			users: [
				{
					id: "232",
					name: "rb4011-rossi-1299",
					warning: "PPPoE-9234 in status connecting",
					date: "2022-04-27 10:02:44",
				},
				{
					id: "132",
					name: "rb4011-tozi-1472",
					warning: "Memory High (92%)",
					date: "2022-04-27 10:00:21",
				},
			],
		};
	},
};
</script>
