// store/index.js
import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth";
import { settings } from "./settings";
import permissions from "./permissions";
import { monitoringLogin } from "./monitoringLogin";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		settings,
		permissions,
		monitoringLogin,
	},
});
