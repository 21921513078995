<template>
	<div class="md-layout text-center">
		<div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<form @submit.prevent="handleSubmit">
				<login-card header-color="transparent" :logo="logo">
					<template v-if="!token">
						<md-field class="md-form-group" slot="description">
							<h4>{{ $t("forgotPassword.insertEmail") }}</h4>
						</md-field>
						<md-field class="md-form-group" slot="inputs">
							<md-icon>email</md-icon>
							<label>{{ $t("forgotPassword.email") }}</label>
							<md-input v-model="email" type="email" required></md-input>
						</md-field>
						<md-button slot="footer" class="md-primary md-round" type="submit">
							{{ $t("forgotPassword.sendResetLink") }}
						</md-button>
					</template>
					<template v-else>
						<template v-if="isTokenValid">
							<md-field class="md-form-group" slot="description">
								<h4 style="text-align: center">{{ $t("forgotPassword.newPassword") }}</h4>
							</md-field>
							<md-field class="md-form-group" slot="inputs">
								<md-icon>lock_outline</md-icon>
								<label>{{ $t("forgotPassword.newPassword") }}</label>
								<md-input v-model="newPassword" type="password" required></md-input>
							</md-field>
							<md-field class="md-form-group" slot="inputs">
								<md-icon>lock_outline</md-icon>
								<label>{{ $t("forgotPassword.confirmPassword") }}</label>
								<md-input v-model="confirmPassword" type="password" required></md-input>
							</md-field>
							<md-button slot="footer" class="md-primary md-round" type="submit">
								{{ $t("forgotPassword.resetPassword") }}
							</md-button>
						</template>
						<template v-else>
							<md-field slot="description">
								<h2>{{ tokenError }}</h2>
							</md-field>
							<md-button slot="footer" class="md-primary md-round" @click="$router.push('/login')">
								{{ $t("forgotPassword.backToLogin") }}
							</md-button>
						</template>
					</template>
				</login-card>
				<md-snackbar
					class="custom-snackbar"
					md-position="center"
					:md-duration="duration"
					:md-active.sync="showSnackbar">
					<p>
						<b>{{ snackbarMessageLine1 }}</b>
						<br />{{ snackbarMessageLine2 }}
					</p>
				</md-snackbar>
			</form>
		</div>
	</div>
</template>

<script>
import { LoginCard } from "@/components";
import Swal from "sweetalert2";

export default {
	components: {
		LoginCard,
	},
	props: {
		logo: {
			type: String,
			default: () => process.env.VUE_APP_LOGO_PATH || "./img/logo-login.png",
		},
	},
	data() {
		return {
			email: null,
			newPassword: null,
			confirmPassword: null,
			token: this.$route.query.token || null,
			showSnackbar: false,
			snackbarMessageLine1: "",
			snackbarMessageLine2: "",
			duration: 5000,
			isTokenValid: false,
			tokenError: "",
		};
	},
	async mounted() {
		if (this.token) {
			await this.verifyToken();
		}
	},
	methods: {
		async verifyToken() {
			try {
				await this.$store.dispatch("auth/verifyResetToken", this.token);
				this.isTokenValid = true;
			} catch (error) {
				this.isTokenValid = false;
				this.tokenError = error.response?.data?.message || this.$t("forgotPassword.invalidToken");
				this.showSnackbar = true;
				this.snackbarMessageLine1 = this.tokenError;
			}
		},
		async handleSubmit() {
			try {
				if (this.token) {
					await this.resetPassword();
				} else {
					await this.requestReset();
				}
			} catch (error) {
				this.showSnackbar = true;
				this.snackbarMessageLine1 = this.$t("forgotPassword.error");
				this.snackbarMessageLine2 = this.$t("responses.error.footer");
			}
		},

		async requestReset() {
			Swal.fire({
				title: this.$t("forgotPassword.loading.title"),
				html: this.$t("forgotPassword.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					try {
						this.$store.dispatch("auth/forgotPassword", this.email);
						Swal.close(); // Chiudi la notifica di attesa
						Swal.fire({
							icon: "success",
							title: this.$t("forgotPassword.success.title"),
							text: this.$t("forgotPassword.success.content"),
							footer: this.$t("forgotPassword.success.footer"),
							backdrop: `rgba(0,150,0,0.4)`,
						});
						this.$router.push("/login");
					} catch (error) {
						this.showSnackbar = true;
						this.snackbarMessageLine1 = this.$t("forgotPassword.error");
						this.snackbarMessageLine2 = error.response?.data?.error || this.$t("responses.error.footer");
					}
				},
			});
		},

		async resetPassword() {
			if (this.newPassword !== this.confirmPassword) {
				Swal.fire({
					icon: "error",
					title: this.$t("forgotPassword.errors.title"),
					text: this.$t("forgotPassword.passwordMismatch"),
					footer: this.$t("responses.error.footer"),
					backdrop: `rgba(255,0,0,0.4)`,
				});
				return;
			}

			Swal.fire({
				title: this.$t("forgotPassword.loading.title"),
				html: this.$t("forgotPassword.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
				},
			});

			try {
				const response = await this.$store.dispatch("auth/resetPassword", {
					token: this.token,
					password: this.newPassword,
				});

				if (response.message === "Password successfully reset") {
					Swal.close();
					Swal.fire({
						icon: "success",
						title: this.$t("forgotPassword.success.title"),
						text: this.$t("forgotPassword.passwordResetSuccess"),
						footer: this.$t("forgotPassword.redirectingToLogin"),
						backdrop: `rgba(0,150,0,0.4)`,
					});
					this.$router.push("/login");
				} else {
					throw new Error("Risposta non valida");
				}
			} catch (error) {
				Swal.close();
				Swal.fire({
					icon: "error",
					title: this.$t("forgotPassword.errors.title"),
					text: this.$t("forgotPassword.passwordLengthError"),
					footer: this.$t("responses.error.footer"),
					backdrop: `rgba(255,0,0,0.4)`,
				});
			}
		},
	},
};
</script>

<style scoped>
.custom-snackbar {
	top: 0;
	transform: translate(0, 100%);
	background-color: #ff0000;
	color: #ffffff;
	border-radius: 4px;
	padding: 12px 24px;
	font-size: 18px;
	z-index: 1100;
	transition: all 0.3s ease;
}
</style>
