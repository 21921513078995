import evoapi from "./evoapi";

class UserService {
	getUser() {
		return evoapi.get("/auth/userdata");
	}

	getLiveUser() {
		return evoapi.get("/auth/liveuserdata");
	}
}

export default new UserService();
