<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>business</md-icon>
					</div>
					<h4 class="title">{{ $t("tenants.list.title") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							v-if="hasPermission('admin:tenant_add') || customerRole === 'super-admin'"
							class="md-info md-round"
							@click.native="addTenantRequest()">
							<p v-html="$t('tenants.add')"></p>
							<md-icon>add_business</md-icon>
						</md-button>
						<md-button class="md-info md-round" @click.native="fetchData()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						@md-selected="onSelect"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="pageOption in pagination.perPageOptions"
										:key="pageOption"
										:label="pageOption"
										:value="pageOption">
										{{ pageOption }}
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-input
									:placeholder="$t('general.search')"
									v-model="filters.query"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<!-- * TABLE CONTENT *-->
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_id')"
								md-sort-by="tenant_id"
								style="width: 5%"
								class="centered-content bordered-cell">
								{{ item.tenant_id }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_name')"
								md-sort-by="name"
								class="centered-content bordered-cell">
								{{ item.name }}
							</md-table-cell>

							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_logo')"
								md-sort-by="logo"
								class="centered-content bordered-cell">
								<a :href="item.logo" target="_blank">
									<img
										:src="item.logo"
										alt="Logo"
										style="width: 50px; height: auto; background-color: #3b3b3b" />
								</a>
							</md-table-cell>
							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_domain')"
								md-sort-by="domain"
								class="centered-content bordered-cell">
								{{ item.domain }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('tenants.list.footer.tenant_platform_name')"
								md-sort-by="platform_name"
								class="centered-content bordered-cell">
								{{ item.platform_name }}
							</md-table-cell>

							<md-table-cell
								:md-label="$t('fields.actions.label')"
								class="centered-content bordered-cell">
								<div></div>
								<md-button
									class="md-just-icon md-simple md-warning"
									@click.native="editTenant(item)"
									v-if="hasPermission('admin:tenant_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('tenants.list.action.edit_tenant_tooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>edit</md-icon>
										<div style="font-size: xx-small">Edit</div>
									</div>
								</md-button>

								<md-button
									v-if="hasPermission('admin:tenant_delete') || customerRole === 'super-admin'"
									class="md-just-icon md-simple md-danger"
									@click.native="deleteTenant(item)">
									<md-tooltip md-direction="top"
										><p v-html="$t('tenants.list.action.delete_tenant_tooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">Delete</div>
									</div>
								</md-button>

								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>
<script>
import { Pagination } from "@/components";
import TokenService from "@/services/token";
import evoapi from "@/services/evoapi";
import { swalUtils } from "@/mixins/swal";
import { tableMixins } from "@/mixins/pagination";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import { mapGetters } from "vuex";

export default {
	name: "ListTenants",
	components: {
		Pagination,
	},
	mixins: [swalUtils, tableMixins],
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			customerRole: TokenService.getUser()["role"],
			currentSort: "tenant_id",
			currentSortOrder: "asc",
			tableData: [],
			searchedData: [],
			tenants: {},
			searchQuery: "",
			footerTable: [this.$t("tenants.list.footer.tenant_name")],
			showTenantForm: false,
			editingTenant: null,
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			filters: {
				query: "",
			},
		};
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["name"],
				threshold: 0.3,
			});
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},

		async fetchData() {
			evoapi
				.get(`/tenants`)
				.then((res) => {
					if (typeof res.data === "string") {
						this.tableData = JSON.parse(res.data);
					} else {
						this.tableData = res.data;
					}
					this.tableData = res.data;
					if (!Array.isArray(this.tableData)) {
						this.searchedData = [this.tableData];
					} else {
						this.searchedData = this.tableData;
					}
				})
				.catch((err) => {
					this.swalBasicErrorDialog();
					// console.error("Error while fetching tenant data:", err);
				});
		},

		deleteTenant(item) {
			Swal.fire({
				title: this.$t("tenants.list.action.delete_tenant"),
				text: this.$t("tenants.list.action.delete_tenant_confirm", { value: item.tenant_id }),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.reset_password.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					evoapi
						.delete(`/tenants/${item.tenant_id}`)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							Swal.fire({
								title: this.$t("responses.deleted_success.title"),
								html: this.$t("responses.deleted_success.content", { subject: item.name }),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							});
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.tenant_id === item.tenant_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},
		addTenantRequest() {
			this.$router.push({ name: "Add Tenant" });
		},
		editTenant(tenant) {
			this.$router.push({
				name: "Edit Tenant",
				params: { tenant: tenant, tenant_id: tenant.tenant_id },
			});
		},
		resetForm() {
			this.showTenantForm = false;
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		onSelect(items) {
			this.selectedTenants = [];
			items.forEach((item) => {
				this.selectedTenants.push(item.tenant_id);
			});
		},
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				//console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
</style>
