<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(submit)">
					<md-card>
						<md-card-content>
							<!-- Password -->
							<md-card v-if="hasPermission('profile:profile_password_change')">
								<md-card-header class="md-card-header-icon md-card-header-blue">
									<div class="card-text">
										<h4 class="title">{{ $t("profile.password_settings") }}</h4>
									</div>
									<br />
								</md-card-header>
								<md-card-content>
									<ValidationProvider
										name="new_password"
										rules="passwordRegex"
										v-slot="{ passed, failed }"
										ref="new_password">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>{{ $t("fields.new_password.placeholder") }}</label>
											<md-input v-model="new_password"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>

									<ValidationProvider
										name="confirm"
										rules="requiredIfPassword:@new_password"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>{{ $t("fields.confirm_password.placeholder") }}</label>
											<md-input v-model="confirm"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</md-card-content>
							</md-card>
							<!-- 2FA -->
							<md-card v-if="hasPermission('profile:profile_2fa')">
								<md-card-header class="md-card-header-icon md-card-header-warning">
									<div class="card-text">
										<h4 class="title">{{ $t("profile.2fa.title") }}</h4>
									</div>
									<br />
								</md-card-header>
								<md-card-content>
									<p v-html="$t('profile.2fa.description')"></p>
									<div v-if="!is2faEnabled">
										<h4 v-html="$t('profile.2fa.disabled')"></h4>
										<md-button @click="enable2FA" class="md-success">{{
											$t("profile.2fa_enable.button")
										}}</md-button>
									</div>
									<div v-else>
										<div v-if="showQrCode">
											<hr />
											<!-- Mostra il codice QR per la configurazione 2FA -->
											<div>
												<h4>{{ $t("profile.2fa.qrcode_title") }}</h4>
												<p v-html="$t('profile.2fa.qrcode_description')"></p>
												<vue-qrcode :value="qrCodeUrl"></vue-qrcode>
											</div>
											<hr />
											<!-- Mostra i codici di backup -->
											<div>
												<h4>{{ $t("profile.2fa.backup_codes_title") }}</h4>
												<p v-html="$t('profile.2fa.backup_codes_description')"></p>
												<code>
													<ul>
														<li v-for="(code, index) in backupCodes" :key="index">
															{{ code }}
														</li>
													</ul>
												</code>
											</div>
											<hr />
											<!-- Campo per la verifica del token 2FA -->
											<div>
												<h4>{{ $t("profile.2fa.verify_title") }}</h4>
												<md-field>
													<label>Inserisci il codice 2FA per la verifica</label>
													<md-input v-model="verificationCode" type="text"></md-input>
												</md-field>
												<md-button @click="verify2FA" class="md-warning">{{
													$t("profile.2fa_verify.button")
												}}</md-button>
											</div>
										</div>
										<div v-if="!showQrCode">
											<h4 v-html="$t('profile.2fa.enabled')"></h4>
											<md-button @click="disable2FA" class="md-danger">{{
												$t("profile.2fa_disable.button")
											}}</md-button>
										</div>
									</div>
								</md-card-content>
							</md-card>
							<!-- Contact Monitoring -->
							<md-card v-if="hasPermission('profile:profile_contact')">
								<md-card-header class="md-card-header-icon md-card-header-primary">
									<div class="card-text">
										<h4 class="title">{{ $t("profile.contact_monitoring") }}</h4>
									</div>
									<br />
								</md-card-header>
								<div style="display: flex">
									<md-card-content style="flex: 0.7">
										<!-- email -->
										<ValidationProvider
											name="email"
											rules="required|email"
											v-slot="{ passed, failed }">
											<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
												<label>{{ $t("fields.email.contact_email") }}</label>
												<md-input v-model="email"> </md-input>
												<slide-y-down-transition>
													<md-icon class="error" v-show="failed">close</md-icon>
												</slide-y-down-transition>
												<slide-y-down-transition>
													<md-icon class="success" v-show="passed">done</md-icon>
												</slide-y-down-transition>
											</md-field>
										</ValidationProvider>

										<ValidationProvider
											name="contact_email_enabled"
											rules="required"
											v-slot="{ passed, failed }">
											<md-field
												:class="[
													{ 'md-error': failed },
													{ 'md-valid': passed },
													'checkbox-label',
												]">
												<table>
													<tr>
														<td>
															<md-checkbox v-model="contact_email_enabled"></md-checkbox>
														</td>
														<td>
															{{ $t("fields.email.contact_email_enabled") }}
														</td>
													</tr>
												</table>
												<slide-y-down-transition>
													<md-icon class="error" v-show="failed">close</md-icon>
												</slide-y-down-transition>
												<slide-y-down-transition>
													<md-icon class="success" v-show="passed">done</md-icon>
												</slide-y-down-transition>
											</md-field>
										</ValidationProvider>
										<br />

										<!-- Telegram -->
										<ValidationProvider name="contact_telegram" v-slot="{ passed, failed }">
											<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
												<label>{{ $t("fields.telegram.contact_telegram") }}</label>
												<md-input v-model="contact_telegram"> </md-input>
												<slide-y-down-transition>
													<md-icon class="error" v-show="failed">close</md-icon>
												</slide-y-down-transition>
												<slide-y-down-transition>
													<md-icon class="success" v-show="passed">done</md-icon>
												</slide-y-down-transition>
											</md-field>
										</ValidationProvider>

										<ValidationProvider
											name="contact_telegram_enabled"
											rules="required"
											v-slot="{ passed, failed }">
											<md-field
												:class="[
													{ 'md-error': failed },
													{ 'md-valid': passed },
													'checkbox-label',
												]">
												<table>
													<tr>
														<td>
															<md-checkbox
																v-model="contact_telegram_enabled"></md-checkbox>
														</td>
														<td>
															{{ $t("fields.telegram.contact_telegram_enabled") }}
														</td>
													</tr>
												</table>
												<slide-y-down-transition>
													<md-icon class="error" v-show="failed">close</md-icon>
												</slide-y-down-transition>
												<slide-y-down-transition>
													<md-icon class="success" v-show="passed">done</md-icon>
												</slide-y-down-transition>
											</md-field>
										</ValidationProvider>
									</md-card-content>
									<div style="flex: 0.3; padding: 10px 10px 10px 10px">
										<!-- Aggiungi qui le tue informazioni -->
										<h3>{{ $t("profile.info_title") }}</h3>
										<p v-html="$t('profile.info_content')" />
										<p v-html="$t('profile.info_content_telegram')" />
									</div>
								</div>
							</md-card>
						</md-card-content>

						<md-card-actions>
							<md-button
								v-if="hasPermission('profile:profile_update')"
								type="submit"
								class="md-success"
								>{{ $t("profile.cta") }}</md-button
							>
						</md-card-actions>
					</md-card>
				</form>
				<md-snackbar
					class="custom-snackbar-purple"
					md-position="center"
					:md-duration="duration"
					:md-active.sync="showSnackbar">
					<p>
						<b>{{ snackbarMessageLine1 }}</b
						><br />{{ snackbarMessageLine2 }}
					</p>
				</md-snackbar>
			</ValidationObserver>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import TokenService from "@/services/token";
import UserService from "@/services/user";
import evoapi from "@/services/evoapi";
import Swal from "sweetalert2";
import Vue from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { mapGetters } from "vuex";

extend("required", required);
extend("regex", regex);
extend("email", email);
extend("passwordRegex", {
	validate(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(value);
	},
	message: "fields.new_password.validation",
});

extend("requiredIfPassword", {
	params: ["target"],
	validate(value, { target }) {
		return target.length > 0 && value == target;
	},
});

export default {
	components: {
		SlideYDownTransition,
		VueQrcode,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
	},
	data() {
		return {
			email: "",
			contact_email_enabled: false,
			contact_telegram: "",
			contact_telegram_enabled: false,
			new_password: "",
			confirm: "",
			showSnackbar: false,
			snackbarMessageLine1: "",
			snackbarMessageLine2: "",
			duration: 5000,
			is2faEnabled: false, // Da aggiornare in base allo stato corrente dell'utente
			qrCodeUrl: "",
			showQrCode: false,
			backupCodes: [],
			verificationCode: "",
		};
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			UserService.getLiveUser().then((userData) => {
				this.email = userData.data.contact_email;
				this.contact_email_enabled = userData.data.contact_email_enabled;
				this.contact_telegram = userData.data.contact_telegram;
				this.contact_telegram_enabled = userData.data.contact_telegram_enabled;
				this.is2faEnabled = userData.data.otp_enabled;
			}, this);
		},
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.post("/auth/update-profile", {
					contact_email: this.email,
					contact_email_enabled: this.contact_email_enabled,
					contact_telegram: this.contact_telegram,
					contact_telegram_enabled: this.contact_telegram_enabled,
					password: this.new_password,
				})
				.then((response) => {
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("profile.title");
					this.snackbarMessageLine2 = this.$t("profile.success");
					this.new_password = "";
					this.confirm = "";
					// this.$router.push({ name: "Home" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		enable2FA() {
			evoapi
				.post(`/users/otp/generate`)
				.then((response) => {
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("profile.2fa_enable.title");
					this.snackbarMessageLine2 = this.$t("profile.2fa_enable.message");

					this.qrCodeUrl = response.data.otpAuthURL;
					this.backupCodes = response.data.backupCodes;
					this.is2faEnabled = true;
					this.showQrCode = true;
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content_message") + error.response.data,
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		verify2FA() {
			evoapi
				.post(`/users/otp/verify`, {
					token: this.verificationCode,
				})
				.then((response) => {
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("profile.2fa_verify.title");
					this.snackbarMessageLine2 = this.$t("profile.2fa_verify.message");
					// La verifica è riuscita, potresti voler aggiornare lo stato della 2FA o navigare l'utente
					this.is2faEnabled = true;
					this.showQrCode = false;
					// ricarichiamo la pagina per aggiornare lo stato
					this.fetchData();
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("profile.2fa_verify.error"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		disable2FA() {
			evoapi
				.post(`/users/otp/disable`)
				.then((response) => {
					this.showSnackbar = true;
					this.snackbarMessageLine1 = this.$t("profile.2fa_disable.title");
					this.snackbarMessageLine2 = this.$t("profile.2fa_disable.message");
					// 2FA disabilitata con successo
					this.is2faEnabled = false;
					// ricarichiamo la pagina per aggiornare lo stato
					this.fetchData();
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content_message") + error.response.data,
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/style";

.md-card .md-card-actions {
	border: none;
}

.checkbox-wrapper {
	display: flex;
	align-items: center;
}
</style>
