<template>
	<ValidationObserver v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(submit)">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-text">
						<h4 class="title">{{ $t("template.new") }}</h4>
					</div>
					<br /><br />
					<div class="text-right">
						<md-button class="md-info md-round md-small" @click.native="addNewVariable()">
							{{ $t("template.addVariable") }}
							<md-icon>add</md-icon>
						</md-button>
					</div>
				</md-card-header>

				<md-card-content>
					<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("template.templateName") }}</label>
							<md-input v-model="templateName"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider name="description" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("template.templateDescription") }}</label>
							<md-textarea v-model="templateDescription" md-autogrow ref="templateDescription">
							</md-textarea>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider
						name="fromVersion"
						:rules="{
							required: true,
							regex: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
						}"
						v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("template.validFrom") }}</label>
							<md-input v-model="fromVersion" :value="fromVersion || '6.0.0'"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider
						name="toVersion"
						:rules="{
							required: true,
							regex: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
						}"
						v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("template.validTo") }}</label>
							<md-input v-model="toVersion" :value="toVersion || '7.99.99'"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider name="text" rules="required" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" maxlength="10">
							<label>{{ $t("template.templateText") }}</label>
							<md-textarea
								v-model="templateText"
								md-autogrow
								ref="templateText"
								style="
									background-color: black;
									color: white;
									-webkit-text-fill-color: white !important;
									font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
									padding: 1rem;
									font-size: small;
									max-height: 10000px;
								">
							</md-textarea>
						</md-field>
					</ValidationProvider>
				</md-card-content>

				<md-card-actions>
					<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
				</md-card-actions>
			</md-card>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);
extend("regex", regex);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			templateName: "",
			templateDescription: "",
			templateText: "",
			fromVersion: "6.0.0",
			toVersion: "7.99.99",
		};
	},
	methods: {
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/templates", {
					name: this.templateName,
					description: this.templateDescription,
					text: this.templateText,
					from_version: this.fromVersion,
					to_version: this.toVersion,
				})
				.then((response) => {
					this.$router.push({ name: "List Templates" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		addNewVariable() {
			let ta = this.$refs.templateText.$el;
			let cpa = ta.selectionStart;
			let cpe = ta.selectionEnd;
			Swal.fire({
				text: this.$t("dialogs.add_variable_template.content"),
				input: "text",
				inputPlaceholder: this.$t("dialogs.add_variable_template.placeholder"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.add_variable_template.validation");
					}
				},
				showCancelButton: true,
				confirmButtonText: this.$t("dialogs.add_variable_template.ok"),
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					this.templateText =
						this.templateText.substring(0, cpa) +
						"<<" +
						result.value +
						">>" +
						this.templateText.substring(cpe);
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
	border: none;
}
</style>
