<template>
	<router-view></router-view>
</template>

<script>
import EventBus from "./common/eventbus";
import TokenService from "./services/token";

export default {
	methods: {
		logOut() {
			// this.$store.dispatch('auth/logout');
			TokenService.removeTokens();
			TokenService.removeUser();
			this.$router.push("/login");
		},
	},
	computed: {
		locale() {
			return this.$store.getters["settings/locale"];
		},
	},
	watch: {
		locale() {
			this.$i18n.locale = this.locale;
		},
	},
	mounted() {
		EventBus.on("logout", () => {
			this.logOut();
		});

		this.$store.dispatch("settings/init");
		this.$i18n.locale = this.$store.getters["settings/locale"];
	},
	beforeDestroy() {
		EventBus.remove("logout");
	},
};
</script>
