<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<template slot="header">
					<h3 class="title">{{ $t("provisioner.new") }}</h3>
					<h5 class="category">
						{{ $t("provisioner.letstart") }}
					</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label"> {{ $t("provisioner.data") }}</template>
					<first-step ref="step1" @on-validated="wizardComplete"></first-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from "./WizardNewSteps/FirstStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	data() {
		return {
			wizardModel: {},
			finishButtonText: "Add provisioner",
		};
	},
	components: {
		FirstStep,
		SimpleWizard,
		WizardTab,
	},
	methods: {
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		wizardComplete() {
			let req = {
				name: this.$refs.step1.name,
				provisioning_port: this.$refs.step1.port,
			};

			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/provisioners", req)
				.then((response) => {
					let commands = response.data.join("<br><br>");
					Swal.fire({
						title: this.$t("dialogs.provisioners_commands_alt.title"),
						html: this.$t("dialogs.provisioners_commands_alt.content", { commands }),
						// buttonsStyling: false,
						// confirmButtonClass: "md-button md-success",
						confirmButtonText: this.$t("dialogs.provisioners_commands_alt.ok"),
						showCloseButton: true,
						icon: "success",
						allowOutsideClick: false,
						backdrop: `rgba(0,0,123,0.4)`,
					}).then((result) => {
						if (result.isConfirmed) {
							if (window.clipboardData && window.clipboardData.setData) {
								// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
								return window.clipboardData.setData("Text", commands);
							} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
								var textarea = document.createElement("textarea");
								textarea.textContent = commands;
								textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
								document.body.appendChild(textarea);
								textarea.select();
								try {
									return document.execCommand("copy"); // Security exception may be thrown by some browsers.
								} catch (ex) {
								} finally {
									document.body.removeChild(textarea);
								}
							}
						}
					});
					this.$router.push({ name: "List Provisioners" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					} else {
						// console.log(error);
					}
				});
		},
	},
};
</script>

<style scoped>
.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}

.copy-paste-password {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: large;
	text-align: center;
}
</style>
