<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<div v-if="isLoading" class="loading-overlay">
			<div class="loading-container">
				<img src="img/loading.gif" alt="Loading" class="loading-animation" width="200" />
			</div>
		</div>
		<form @submit.prevent="handleSubmit(submit)">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-text">
						<h4 class="title">{{ $t("contentFiltering.new") }}</h4>
					</div>
					<br /><br />
				</md-card-header>

				<md-card-content>
					<!-- Autocomplete Component -->
					<div class="md-layout">
						<div class="md-layout-item md-small-size-100">
							<md-field class="md-no-border">
								<md-autocomplete
									v-model="selectedContentFilteringVersions"
									:md-options="
										contentFilteringVersions.map((contentFilteringVersion) => ({
											value: contentFilteringVersion.id,
											label: contentFilteringVersion.name,
											toLowerCase: () => contentFilteringVersion.name.toLowerCase(),
											toString: () => contentFilteringVersion.name,
										}))
									">
									<label>{{ $t("contentFiltering.selectContentFilteringVersions") }}</label>

									<template slot="md-autocomplete-item" slot-scope="{ item }">{{
										item.label
									}}</template>

									<template slot="md-autocomplete-empty">
										<b>{{ $t("contentFiltering.noRoutersFound") }}</b>
									</template>
								</md-autocomplete>
							</md-field>
						</div>
					</div>
				</md-card-content>

				<md-card-content>
					<!-- Autocomplete Component -->
					<div class="md-layout">
						<div class="md-layout-item md-small-size-100">
							<md-field class="md-no-border">
								<md-autocomplete
									v-model="selectedRouter"
									:md-options="
										routers.map((router) => ({
											value: router.id,
											label: router.name,
											toLowerCase: () => router.name.toLowerCase(),
											toString: () => router.name,
										}))
									">
									<label>{{ $t("contentFiltering.selectRouter") }}</label>

									<template slot="md-autocomplete-item" slot-scope="{ item }">{{
										item.label
									}}</template>

									<template slot="md-autocomplete-empty">
										<b>{{ $t("contentFiltering.noRoutersFound") }}</b>
									</template>
								</md-autocomplete>
							</md-field>
						</div>
					</div>
				</md-card-content>

				<md-card-actions>
					<md-button type="button" class="md-back" @click="goBack">{{ $t("general.close") }}</md-button>
					<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
				</md-card-actions>
			</md-card>
		</form>
	</ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import evoapi from "@/services/evoapi";
// Assicurati di importare md-autocomplete se non è registrato globalmente

export default {
	components: {
		ValidationObserver,
		// Aggiungi qui md-autocomplete se non è registrato globalmente
	},
	data() {
		return {
			selectedRouter: "",
			selectedContentFilteringVersions: "",
			routers: [],
			contentFilteringVersions: [
				{
					name: "FlashStart PRO",
					id: 1,
				},
				{
					name: "FlashStart PROPLUS",
					id: 2,
				},
			],
			isLoading: false,
		};
	},
	methods: {
		goBack() {
			this.$router.push({ name: "List Content Filtering" });
		},
		isRouterValid() {
			return this.routers.some((router) => router.id === this.selectedRouter.value);
		},
		submit() {
			if (!this.selectedRouter || !this.isRouterValid()) {
				Swal.fire({
					icon: "error",
					title: this.$t("responses.error.title"),
					text: this.$t("contentFiltering.error.correctRouter"),
				});
				return;
			}
			this.isLoading = true; // start loading
			let req = {
				device_id: this.selectedRouter.value,
				service_type_id: this.selectedContentFilteringVersions.value,
			};
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/content-filtering/activate", req)
				.then((response) => {
					this.isLoading = false;
					Swal.close();
					this.$router.push({ name: "List Content Filtering" });
				})
				.catch((error) => {
					this.isLoading = false; // Fine del caricamento anche in caso di errore
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							html:
								this.$t("licence.activate.error.content") +
								"<code>" +
								error.response.data.error +
								"</code>",
							footer: this.$t("responses.error.footer"),
							showCloseButton: false,
							icon: "error",
							allowOutsideClick: false,
							backdrop: `rgba(150,0,0,0.4)`,
						});
					}
				});
		},
	},
	mounted() {
		let userData = TokenService.getUser();

		// Prima chiamata API per ottenere tutti i router
		evoapi
			.get("/customers/" + userData.customerId + "/devices")
			.then((response) => {
				// filter the response. Take only the routers that have "last_seen" property in the last 5 minutes
				let allRouters = response.data.filter((device) => {
					let lastSeen = new Date(device.last_seen);
					let now = new Date();
					let diff = now - lastSeen;
					return diff < 300000;
				});
				// Assumi che i dati di risposta siano un array di oggetti con proprietà 'name' e 'id'
				allRouters = allRouters.map((device) => ({
					name: device.name + " [ " + device.public_ip + " ] " + "(ID: " + device.device_id + ")",
					id: device.device_id,
				}));
				// Seconda chiamata API per ottenere i router attivati
				evoapi
					.get("/customers/" + userData.customerId + "/content-filtering/list")
					.then((activatedResponse) => {
						let activatedRouters = new Set(activatedResponse.data.map((device) => device.device_id));

						// Filtra i router escludendo quelli attivati
						this.routers = allRouters.filter((router) => !activatedRouters.has(router.id));
					})
					.catch((error) => {
						//console.error("Errore nel caricamento dei router attivati:", error);
						// Gestisci l'errore come preferisci
					});
			})
			.catch((error) => {
				//console.error("Errore nel caricamento dei router:", error);
				// Gestisci l'errore come preferisci
			});
	},
};
</script>

<style lang="scss" scoped>
// I tuoi stili esistenti
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
