<template>
	<div
		class="sidebar"
		:data-color="activeColor"
		:data-image="backgroundImage"
		:data-background-color="backgroundColor"
		:style="sidebarStyle">
		<div class="logo">
			<a href="" class="simple-text logo-mini">
				<!-- <div class="logo-img"> -->
				<div>
					<img :src="logo" />
				</div>
			</a>
			<a href="" class="simple-text logo-normal">
				<template>{{ platform_name }}</template>
			</a>
			<div class="navbar-minimize">
				<md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
					<i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
					<i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
				</md-button>
			</div>
		</div>
		<div class="sidebar-wrapper" ref="sidebarScrollArea">
			<slot></slot>
			<md-list class="nav">
				<slot name="links">
					<sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
						<sidebar-item
							v-for="(subLink, index) in link.children"
							:key="subLink.name + index"
							:link="subLink">
						</sidebar-item>
					</sidebar-item>
				</slot>
			</md-list>
		</div>
	</div>
</template>
<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	name: "sidebar",
	props: {
		activeColor: {
			type: String,
			default: "green",
			validator: (value) => {
				let acceptedValues = ["", "purple", "azure", "green", "orange", "danger", "rose"];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		backgroundImage: {
			type: String,
			default: "./img/sidebar-2.jpg",
		},
		backgroundColor: {
			type: String,
			default: "black",
			validator: (value) => {
				let acceptedValues = ["", "black", "white", "red"];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		sidebarLinks: {
			type: Array,
			default: () => [],
		},
		autoClose: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			tenants: {},
			logo: "https://media.tenor.com/YtAOA9y7VG0AAAAM/loading.gif",
			platform_name: "Platform Name loading...",
		};
	},
	provide() {
		return {
			autoClose: this.autoClose,
		};
	},
	mounted() {
		this.fetchTenant();
	},
	methods: {
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
		async fetchTenant() {
			try {
				let response = await evoapi.get(`/tenants/customer/${TokenService.getUser()["customerId"]}`);
				let tenantData = response.data;
				if (tenantData && tenantData.logo) {
					this.logo = tenantData.logo; // Aggiorna la proprietà logo con il percorso del logo ricevuto
					this.platform_name = tenantData.platform_name;
				}
				this.tenants[tenantData.tenant_id] = tenantData;
			} catch (error) {
				this.swalBasicErrorDialog();
				// console.error("Error while fetching customer data:", err);
			}
		},
	},
	computed: {
		sidebarStyle() {
			return {
				backgroundImage: `url(${this.backgroundImage})`,
			};
		},
	},
	beforeDestroy() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
		}
	},
};
</script>
<style>
@media (min-width: 992px) {
	.navbar-search-form-mobile,
	.nav-mobile-menu {
		display: none;
	}
}
</style>
