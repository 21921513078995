<template>
	<md-card>
		<md-card-header class="md-card-header-icon md-card-header-green">
			<div class="card-text">
				<h4 class="title">
					{{ customer ? $t("customers.edit_customer.title") : $t("customers.new_customer.title") }}
				</h4>
			</div>
		</md-card-header>
		<md-card-content>
			<ValidationObserver ref="formNewCustomer">
				<form @submit="submitForm">
					<validation-provider v-slot="{ passed, failed }" name="customer-name" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>business</md-icon>
							<label>{{ $t("customers.new_customer.customer_label") }}</label>
							<md-input v-model="customerName" type="text"></md-input>
						</md-field>
					</validation-provider>
					<validation-provider v-slot="{ passed, failed }" name="tenant-id" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>business</md-icon>
							<label>{{ $t("customers.new_customer.tenant_label") }}</label>
							<md-select v-model="tenantId" :key="updateKey">
								<md-option v-for="(tenant, index) in tenants" :key="index" :value="tenant.tenant_id">
									{{ tenant.name }}
								</md-option>
							</md-select>
						</md-field>
					</validation-provider>
					<!-- This div is required for the md-select dropdown to work correctly. Do not remove. -->
					<div v-for="tenant in tenants" :key="tenant.tenant_id" style="display: none">
						{{ tenant.name }}
					</div>
					<div class="d-flex-justify-space-evenly">
						<md-button v-if="!customer" class="md-success" type="submit">
							{{
								$t(
									`customers.new_customer.${
										addUserCheckbox ? "submit_button_with_user" : "submit_button"
									}`
								)
							}}
						</md-button>
						<md-button v-else class="md-success" type="submit">
							{{ $t("customers.edit_customer.submit_button") }}
						</md-button>
						<md-button class="md-danger" type="reset" @click="formReset"
							>{{ $t(`customers.new_customer.reset_button`) }}
						</md-button>
					</div>
				</form>
			</ValidationObserver>
		</md-card-content>
	</md-card>
</template>
<script>
import TokenService from "@/services/token";
import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";

extend("required", required);
extend("regex", regex);
extend("email", email);
extend("passwordRegex", {
	validate(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(value);
	},
	message: "fields.password.validation",
});

extend("requiredIfUser", {
	params: ["target"],
	validate(value, { target }) {
		return target.length > 0 && value == target;
	},
});

export default {
	name: "NewCustomerForm",
	components: {},
	emits: ["onFormReset", "formSubmitted"],
	data() {
		return {
			customerName: "",
			tenantId: null,
			addUserCheckbox: false,
			user: {
				email: "",
				password: "",
			},
			tenants: [],
			updateKey: 0,
		};
	},
	props: {
		customer: {
			type: Object,
			default: null,
		},
	},
	watch: {
		customer: {
			immediate: true,
			handler(newCustomer) {
				if (newCustomer) {
					this.customerName = newCustomer.name;
					this.tenantId = newCustomer.tenant_id;
				}
			},
			deep: true,
		},
	},

	mounted() {
		this.fetchTenants(); // Chiamare la funzione quando il componente viene montato
	},
	methods: {
		notifyError(verticalAlign, horizontalAlign, message) {
			this.$notify({
				timeout: 5000,
				message: message,
				icon: "add_alert",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: "success",
			});
		},

		fetchTenants() {
			// if is super-admin
			if (TokenService.getUser()["role"] === "super-admin") {
				evoapi.get("/tenants").then((res) => {
					this.tenants = res.data;
					this.tenantId = res.data[0].tenant_id;
					this.updateKey = new Date().getTime(); // Aggiorna la chiave
				});
			} else {
				evoapi.get(`/tenants/customer/${TokenService.getUser()["customerId"]}`).then((res) => {
					this.tenants = [res.data];
					this.tenantId = res.data.tenant_id;
					this.updateKey = new Date().getTime(); // Aggiorna la chiave
				});
			}
		},
		submitForm() {
			return this.$refs.formNewCustomer.validate().then((res) => {
				if (res === false) return;

				if (this.customer) {
					evoapi
						.put("/customers/" + this.customer.customer_id, {
							name: this.customerName,
							tenant_id:
								TokenService.getUser()["role"] === "super-admin"
									? this.tenantId
									: this.customer.tenant_id,
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("customers.list.action.edit_customer_success"));
							this.$router.push({ name: "List Customers" });
						})
						.catch((error) => {
							Swal.fire({
								icon: "error",
								title: this.$t("responses.error.title"),
								text: this.$t("responses.error.content"),
								footer: this.$t("responses.error.footer"),
							});
						});
				} else {
					// if is super-admin, tenant_id = 1
					evoapi
						.post("/customers/", {
							name: this.customerName,
							tenant_id:
								TokenService.getUser()["role"] === "super-admin"
									? this.tenantId
									: TokenService.getUser()["tenant_id"],
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("customers.list.action.add_customer_success"));
							this.$router.push({ name: "List Customers" });
						})
						.catch((error) => {
							if (error.response) {
								if (error.response.status === 500 && error.response.data.error) {
									// Gestione dell'errore specifico restituito dall'API
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										html:
											this.$t("customer.create.error.content") +
											"<code>" +
											error.response.data.error +
											"</code></small",
										footer: this.$t("responses.error.footer"),
										customClass: {
											confirmButton: "md-button md-success",
										},
										buttonsStyling: false,
									});
								} else {
									// Gestione di altri tipi di errori
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							}
						});
				}
			});
		},
		formReset() {
			this.customerName = "";
			this.user.email = "";
			this.user.password = "";
			this.addUserCheckbox = false;
			this.$emit("onFormReset");
			this.$router.push({ name: "List Customers" });
		},
	},
};
</script>

<style lang="scss" scoped>
form {
	@media screen and (min-width: 960px) {
		width: 45vw;
	}

	.mx-2 {
		margin-inline: 0.5rem;
	}

	.d-flex-justify-space-evenly {
		display: flex;
		justify-content: space-evenly;
	}
}
</style>
