import evoapi from "./evoapi";
import TokenService from "./token";
import eventbus from "../common/eventbus";

const setup = (store) => {
	evoapi.interceptors.request.use(
		(config) => {
			const token = TokenService.getLocalAccessToken();
			if (token) {
				config.headers["Authorization"] = "Bearer " + token;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	evoapi.interceptors.response.use(
		(res) => {
			return res;
		},
		async (err) => {
			const originalConfig = err.config;

			if (originalConfig.url !== "/auth/login" && err.response) {
				if (err.response.status === 401 && !originalConfig._retry) {
					originalConfig._retry = true;

					try {
						const rs = await evoapi.post("/auth/refresh", {
							refresh_token: TokenService.getLocalRefreshToken(),
						});

						const access_token = rs.data.access_token;
						const refresh_token = rs.data.refresh_token;

						store.dispatch("auth/refreshToken", access_token, refresh_token);
						TokenService.setLocalTokens(access_token, refresh_token);

						return evoapi(originalConfig);
					} catch (_error) {
						if (_error.response && _error.response.data) {
							return Promise.reject(_error.response.data);
						}
						return Promise.reject(_error);
					}
				}
				if (err.response.status === 403 && err.response.data) {
					eventbus.dispatch("logout");
					// return Promise.reject(err.response.data);
				}
			}

			return Promise.reject(err);
		}
	);
};

export default setup;
