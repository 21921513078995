<template>
	<div class="md-layout-item md-size-100">
		<iframe ref="redirectFrame" style="display: none; width: 100%; height: 1000px; border: 0px"></iframe>
	</div>
</template>

<script>
export default {
	name: "FSIframeWrapper",
	props: {
		sso: String,
	},
	data() {
		return {
			iframeUrl: "",
			baseUrl: "",
		};
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
	mounted() {
		// this.iframeUrl = `https://cloud.flashstart.com/?auth_token=${this.sso}`;
		this.iframeUrl = `https://dashboard.cloudtik.it/?auth_token=${this.sso}`;
		this.$refs.redirectFrame.setAttribute("src", this.iframeUrl);
		this.$refs.redirectFrame.onload = () => {
			this.$refs.redirectFrame.contentWindow.document.body.style.margin = "0px";
			this.$refs.redirectFrame.style.height = document.body.scrollHeight + "px";
		};
		this.$refs.redirectFrame.style.display = "block";
	},
};
</script>

<style scoped lang="scss">
iframe {
	width: 100%;
	height: 75vh;
	margin-block: 0.5rem;
}
</style>
