// services/auth.js
import evoapi from "./evoapi";
import TokenService from "./token";
import UserService from "./user";

class AuthService {
	async login(username, password, otp_token) {
		try {
			const response = await evoapi.post("/auth/login", {
				username,
				password,
				otp_token,
			});
			if (response.status === 200) {
				TokenService.setLocalTokens(response.data.access_token, response.data.refresh_token);
				const userData = await UserService.getUser();
				let user = {
					userId: userData.data.user_id,
					customerId: userData.data.customer_id,
					customerName: userData.data.customer_name,
					isAdmin: userData.data.is_admin,
					role: userData.data.role,
					username: userData.data.username,
					permissions: userData.data.permissions,
				};
				TokenService.setUser(user); // Salva i dati dell'utente nel localStorage
				return user; // Ritorna l'utente dopo averlo impostato nel TokenService
			} else {
				throw new Error("Login failed");
			}
		} catch (error) {
			throw error;
		}
	}

	async logout() {
		try {
			const response = await evoapi.post("/auth/logout");
			if (response.status === 200) {
				TokenService.removeTokens();
				TokenService.removeUser();
			}
		} catch (error) {
			throw error;
		}
	}

	async forgotPassword(email) {
		try {
			const response = await evoapi.post("/auth/forgot-password", { email });
			return response.data;
		} catch (error) {
			throw error;
		}
	}

	async resetPassword({ token, password }) {
		try {
			const response = await evoapi.post("/auth/reset-password", { token, password });
			return response.data;
		} catch (error) {
			throw error;
		}
	}

	async verifyResetToken(token) {
		return evoapi.post("/auth/verify-reset-token", { token });
	}
}

export default new AuthService();
