// store/permissions.js
const state = {
	permissions: [],
};

const mutations = {
	SET_PERMISSIONS(state, permissions) {
		state.permissions = permissions;
	},
};

const actions = {
	setPermissions({ commit }, permissions) {
		commit("SET_PERMISSIONS", permissions);
	},
};

const getters = {
	hasPermission: (state) => (permission) => {
		return state.permissions.includes(permission);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
