<template>
	<md-card>
		<md-card-header class="md-card-header-icon md-card-header-green">
			<div class="card-text">
				<h4 class="title">{{ customer ? $t("users.edit_user.title") : $t("users.new_user.title") }}</h4>
			</div>
		</md-card-header>
		<md-card-content>
			<ValidationObserver ref="formNewUser">
				<form @submit="submitForm">
					<validation-provider v-slot="{ passed, failed }" name="user-username" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>person</md-icon>
							<label>{{ $t("users.new_user.username_label") }}</label>
							<md-input v-model="user.username" type="email"></md-input>
						</md-field>
					</validation-provider>

					<section v-if="isNewUser">
						<validation-provider v-slot="{ passed, failed }" name="user-password" rules="required">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>password</md-icon>
								<label>{{ $t("users.new_user.password_label") }}</label>
								<md-input v-model="user.password" type="password"></md-input>
							</md-field>
						</validation-provider>
					</section>

					<validation-provider v-slot="{ passed, failed }" name="contact-email" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>email</md-icon>
							<label>{{ $t("users.new_user.contact_email_label") }}</label>
							<md-input v-model="user.contact_email" type="email"></md-input>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="customer-id" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>business</md-icon>
							<label>{{ $t("users.new_user.customer_label") }}</label>
							<md-select v-model="user.customer_id" :key="updateKey">
								<md-option
									v-for="(customer, index) in customers"
									:key="index"
									:value="customer.customer_id">
									{{ customer.name }}
								</md-option>
							</md-select>
						</md-field>
					</validation-provider>

					<!-- This div is required for the md-select dropdown to work correctly. Do not remove. -->
					<div v-for="customer in customers" :key="customer.customer_id" style="display: none">
						{{ customer.name }}
					</div>

					<div>
						<md-checkbox v-model="user.is_admin">{{ $t("users.new_user.is_admin_label") }}</md-checkbox>
					</div>

					<section v-if="customerRole === 'super-admin' && user.is_admin">
						<validation-provider v-slot="{ passed, failed }" name="role-name" rules="required">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>rule</md-icon>
								<label>{{ $t("users.new_user.role_label") }}</label>
								<md-select v-model="user.role" :key="updateKey">
									<md-option value="admin">admin</md-option>
									<md-option value="super-admin">super-admin</md-option>
								</md-select>
							</md-field>
						</validation-provider>
					</section>

					<div class="d-flex-justify-space-evenly">
						<md-button class="md-success" type="submit">
							{{ $t("customers.new_customer.submit_button") }}
						</md-button>
						<md-button class="md-danger" type="reset" @click="formReset">
							{{ $t("customers.new_customer.reset_button") }}
						</md-button>
					</div>
				</form>
			</ValidationObserver>
		</md-card-content>
	</md-card>
</template>

<script>
import TokenService from "@/services/token";
import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";

extend("required", required);
extend("regex", regex);
extend("email", email);
extend("passwordRegex", {
	validate(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(value);
	},
	message: "fields.password.validation",
});

export default {
	name: "NewUserForm",
	components: {},
	emits: ["onFormReset", "formSubmitted"],
	data() {
		return {
			customerRole: TokenService.getUser()["role"],
			user: {
				username: "",
				password: "",
				contact_email: "",
				role: "user",
				is_admin: false,
				customer_id: null,
				isNewUser: true,
			},
			customers: [],
			updateKey: 0,
			originalPassword: "",
		};
	},
	props: {
		customer: {
			type: Object,
			default: null,
		},
	},
	computed: {
		isNewUser() {
			return !this.user || !this.user.user_id;
		},
	},
	watch: {
		customer: {
			immediate: true,
			handler(newCustomer) {
				if (newCustomer) {
					this.user = { ...newCustomer };
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.user = { ...this.$route.params.user };
		if (this.user && this.user.password) {
			this.originalPassword = this.user.password;
		}
		this.fetchCustomers();
	},
	methods: {
		notifyError(verticalAlign, horizontalAlign, message) {
			this.$notify({
				timeout: 5000,
				message: message,
				icon: "add_alert",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: "success",
			});
		},

		fetchCustomers() {
			evoapi
				.get(`/customers/${TokenService.getUser()["customerId"]}`)
				.then((res) => {
					if (typeof res.data === "string") {
						this.customers = JSON.parse(res.data);
					} else {
						this.customers = res.data;
					}
				})
				.catch((err) => {
					this.swalBasicErrorDialog();
					// console.error("Error while fetching customer data:", err);
				});
		},
		submitForm() {
			this.$refs.formNewUser.validate().then((res) => {
				if (!res) return;

				// Clona l'oggetto user per non modificarlo direttamente
				const userData = { ...this.user };

				// Verifica se la password non è stata modificata
				if (userData.password === this.originalPassword) {
					delete userData.password; // Rimuovi la password dal payload
				}
				this.user.customer_id = parseInt(this.user.customer_id);

				const apiCall = this.user.user_id ? evoapi.put : evoapi.post;
				const endpoint = this.user.user_id ? `/users/${this.user.user_id}` : "/users/";

				apiCall(endpoint, this.user)
					.then(() => {
						const message = this.user.user_id
							? this.$t("users.list.action.edit_user_success")
							: this.$t("users.list.action.add_user_success");

						this.notifyError("top", "right", message);
						this.$router.push({ name: "List Users" });
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status === 500 && error.response.data.error) {
								// Gestione dell'errore specifico restituito dall'API
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									html:
										this.$t("user.create.error.content") +
										"<code>" +
										error.response.data.error +
										"</code></small",
									footer: this.$t("responses.error.footer"),
									customClass: {
										confirmButton: "md-button md-success",
									},
									buttonsStyling: false,
								});
							} else {
								// Gestione di altri tipi di errori
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							}
						}
					});
			});
		},
		formReset() {
			this.user = {
				username: "",
				password: "",
				contact_email: "",
				role: "user",
				is_admin: false,
				customer_id: null,
			};
			this.$emit("onFormReset");
			this.$router.push({ name: "List Users" });
		},
	},
};
</script>

<style lang="scss" scoped>
form {
	@media screen and (min-width: 960px) {
		width: 45vw;
	}

	.mx-2 {
		margin-inline: 0.5rem;
	}

	.d-flex-justify-space-evenly {
		display: flex;
		justify-content: space-evenly;
	}
}
</style>
