export const tableMixins = {
	data() {
		return {
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
		};
	},
};
