<!-- questo è la selezione del template per il post-provisioning -->
<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<ValidationObserver ref="form">
				<form @submit.prevent="validate">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<div class="card-text">
								<h4 class="title">
									{{ $t("devices_provisioner.title") }}
								</h4>
							</div>
						</md-card-header>

						<md-card-content>
							<div>
								<h5 class="info-text">
									{{ $t("devices_provisioner.template_title") }}
								</h5>
								<div class="md-layout">
									<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
										<md-field class="md-no-border">
											<md-autocomplete
												v-model="template_selected"
												:md-options="
													templates.map((x) => ({
														value: x.template_id,
														label: x.name,
														toLowerCase: () => x.name.toLowerCase(),
														toString: () => x.name,
													}))
												"
												class="">
												<label>Template</label>

												<template slot="md-autocomplete-item" slot-scope="{ item }">{{
													item.label
												}}</template>

												<template slot="md-autocomplete-empty">
													<b>Nessun template, solo pairing</b>
												</template>
											</md-autocomplete>
										</md-field>
									</div>
								</div>
								<h5 class="info-text" v-if="flgShowTemplateVariables">
									{{ $t("devices_provisioner.variables_title") }}
								</h5>
								<div class="md-layout" v-if="flgShowTemplateVariables">
									<div
										class="md-layout-item md-size-100 md-small-size-100"
										v-for="(item, idx) in template.variables"
										v-bind:key="idx">
										<ValidationProvider
											:name="item"
											rules="required"
											v-slot="{ passed, failed }"
											v-if="item != '<<SYS_ProvisioningCounter>>'">
											<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
												<label>{{ item }}</label>
												<md-input v-model="variables[item]" type="text"> </md-input>

												<slide-y-down-transition>
													<md-icon class="error" v-show="failed">close</md-icon>
												</slide-y-down-transition>
												<slide-y-down-transition>
													<md-icon class="success" v-show="passed">done</md-icon>
												</slide-y-down-transition>
											</md-field>
										</ValidationProvider>
									</div>
								</div>
							</div>
						</md-card-content>
						<md-card-actions>
							<md-button type="submit" class="md-success">Configure Devices </md-button>
						</md-card-actions>
					</md-card>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import Swal from "sweetalert2";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	props: ["devices"],
	data() {
		return {
			template_selected: null,
			templates: [],
			flgShowTemplateVariables: false,
			template: null,
			variables: [],
		};
	},
	watch: {
		template_selected: function () {
			if (this.template_selected.value > 0) {
				let userData = TokenService.getUser();
				evoapi
					.get("/customers/" + userData.customerId + "/templates/" + this.template_selected.value)
					.then((response) => {
						this.template = response.data;
						this.flgShowTemplateVariables =
							this.template.variables != null && this.template.variables.length > 0;
					});
			}
		},
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				if (res) {
					let variables = [];
					for (var index in this.variables) {
						variables.push({
							variable_name: index,
							variable_value: this.variables[index],
						});
					}

					let req = {
						devices: this.devices,
					};
					if (this.template_selected != null) {
						req.template = {
							template_id: this.template_selected.value,
							variables: variables,
						};
					} else {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.device_provisioning_error.title"),
							text: this.$t("responses.device_provisioning_error.content"),
							customClass: {
								footer: "onboardingErrorFooter",
							},
						});
						return;
					}

					// Calling API
					Swal.fire({
						title: this.$t("dialogs.device_provisioning_progress.title"),
						html: this.$t("dialogs.device_provisioning_progress.content"),
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});

					let userData = TokenService.getUser();
					evoapi
						.post("/customers/" + userData.customerId + "/devices/provision", req)
						.then((response) => {
							Swal.close();
							this.$router.push({ name: "List notifications" });
						})
						.catch(function (error) {
							if (error.response) {
								let footer = "";
								let stay = true;
								if (error.response.status == 409) {
									if (error.response.data.error_type == 1) {
										footer = this.$t("responses.device_provisioning_error.footer");
										stay = false;
									}
									if (error.response.data.password.length > 0) {
										footer += this.$t("responses.device_provisioning_error.footer_password", {
											password: error.response.data.password,
										});
									}
								}
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
									customClass: {
										footer: "onboardingErrorFooter",
									},
								});
								if (!stay) {
									this.$router.push({ name: "List Devices" });
								}
							} else {
								// console.log(error);
							}
						});
				}
			});
		},
	},
	mounted() {
		// Get data from API
		let userData = TokenService.getUser();
		evoapi.get("/customers/" + userData.customerId + "/templates").then((response) => {
			this.templates = response.data;
		});
	},
};
</script>
<style></style>
