<template>
	<md-menu md-direction="bottom-start">
		<md-button class="md-just-icon md-simple md-primary" md-menu-trigger>
			<md-tooltip md-direction="top">
				<p v-html="$t('device.actionTooltip')"></p>
			</md-tooltip>
			<div class="icon-with-label">
				<md-icon>settings</md-icon>
				<div style="font-size: xx-small">Action</div>
			</div>
		</md-button>

		<md-menu-content>
			<slot></slot>
		</md-menu-content>
	</md-menu>
</template>

<script>
export default {
	data() {
		return {
			isOpen: false,
		};
	},
};
</script>

<style scoped>
.actions-content {
	border-radius: 10px;
	position: absolute;
	top: 100%;
	transform: translateX(-50%);
	left: 50%;
	display: flex;
	flex-direction: row;
	background-color: white;
	align-items: baseline !important;
	padding: 10px;
	z-index: 30;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
}
.actions-container {
	position: relative;
}
</style>
