<template>
	<md-card>
		<md-card-header class="md-card-header-icon md-card-header-green">
			<div class="card-text">
				<h4 class="title">
					{{ license ? $t("usage.licenses.edit_license.title") : $t("usage.licenses.add_license.title") }}
				</h4>
			</div>
		</md-card-header>
		<md-card-content>
			<ValidationObserver ref="formNewLicence">
				<form @submit="submitForm">
					<validation-provider v-slot="{ passed, failed }" name="customer-id" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>business</md-icon>
							<label>{{ $t("users.new_user.customer_label") }}</label>
							<md-select v-model="customerId" :key="updateKey" :disabled="isCustomerIdSet">
								<md-option
									v-for="(customer, index) in customers"
									:key="index"
									:value="customer.customer_id">
									{{ customer.name }}
								</md-option>
							</md-select>
						</md-field>
					</validation-provider>
					<!-- This div is required for the md-select dropdown to work correctly. Do not remove. -->
					<div v-for="customer in customers" :key="customer.customer_id" style="display: none">
						{{ customer.name }}
					</div>

					<validation-provider v-slot="{ passed, failed }" name="device_quantity">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>router</md-icon>
							<label>{{ $t("usage.licenses.form.device_qty") }}</label>
							<md-input v-model="devicesQuantity" type="text"></md-input>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="sdwan_quantity">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>lan</md-icon>
							<label>{{ $t("usage.licenses.form.sdwan_qty") }}</label>
							<md-input v-model="sdwanQuantity" type="text"></md-input>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="content_filtering_quantity">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>lock</md-icon>
							<label>{{ $t("usage.licenses.form.content_filtering_qty") }}</label>
							<md-input v-model="contentFilteringQuantity" type="text"></md-input>
						</md-field>
					</validation-provider>

					<div class="d-flex-justify-space-evenly">
						<md-button class="md-success" type="submit"
							>{{ $t("usage.licenses.form.submit_button") }}
						</md-button>
						<md-button class="md-danger" type="reset" @click="formReset"
							>{{ $t(`usage.licenses.form.reset`) }}
						</md-button>
					</div>
				</form>
			</ValidationObserver>
		</md-card-content>
	</md-card>
</template>
<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

extend("required", required);
extend("regex", regex);
extend("email", email);
extend("passwordRegex", {
	validate(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(value);
	},
	message: "fields.password.validation",
});

extend("requiredIfUser", {
	params: ["target"],
	validate(value, { target }) {
		return target.length > 0 && value == target;
	},
});

export default {
	name: "NewTenantForm",
	components: {},
	emits: ["onFormReset", "formSubmitted"],
	data() {
		return {
			customerRole: TokenService.getUser()["role"],
			devicesQuantity: "",
			sdwanQuantity: "",
			contentFilteringQuantity: "",
			user: {
				email: "",
				password: "",
			},
			customerId: null,
			customers: [],
			updateKey: 0,
			failed: false,
			passed: false,
		};
	},
	computed: {
		isCustomerIdSet() {
			return this.customerId !== null;
		},
	},
	props: {
		license: {
			type: Object,
			default: null,
		},
	},
	watch: {
		license: {
			immediate: true,
			handler(newLicence) {
				if (newLicence) {
					this.customerId = newLicence.customer_id;
					this.devicesQuantity = newLicence.devices_quantity;
					this.sdwanQuantity = newLicence.sdwan_quantity;
					this.contentFilteringQuantity = newLicence.content_filtering_quantity;
				}
			},
			deep: true,
		},
	},
	mounted() {
		let userData = TokenService.getUser();
		this.fetchCustomers();
	},
	methods: {
		notifyError(verticalAlign, horizontalAlign, message) {
			this.$notify({
				timeout: 5000,
				message: message,
				icon: "add_alert",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: "success",
			});
		},
		fetchCustomers() {
			evoapi
				.get(`/customers/${TokenService.getUser()["customerId"]}`)
				.then((res) => {
					if (typeof res.data === "string") {
						this.customers = JSON.parse(res.data);
					} else {
						this.customers = res.data;
					}
				})
				.catch((err) => {
					this.swalBasicErrorDialog();
					// console.error("Error while fetching customer data:", err);
				});
		},
		submitForm() {
			return this.$refs.formNewLicence.validate().then((res) => {
				if (res === false) return;

				if (this.license) {
					evoapi
						.put("/usage_licenses/customer/" + this.customerId, {
							devices_quantity: parseInt(this.devicesQuantity),
							sdwan_quantity: parseInt(this.sdwanQuantity),
							content_filtering_quantity: parseInt(this.contentFilteringQuantity),
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("usage.licenses.action.edit_license_success"));
							this.$router.push({ name: "Licenses" });
						})
						.catch((error) => {
							if (error.response) {
								if (error.response.status === 500 && error.response.data.error) {
									// Gestione dell'errore specifico restituito dall'API
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										html:
											this.$t("tenant.create.error.content") +
											"<code>" +
											error.response.data.error +
											"</code></small",
										footer: this.$t("responses.error.footer"),
										customClass: {
											confirmButton: "md-button md-success",
										},
										buttonsStyling: false,
									});
								} else {
									// Gestione di altri tipi di errori
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							}
						});
				} else {
					evoapi
						.post("/usage_licenses/customer/" + this.customerId, {
							devices_quantity: parseInt(this.devicesQuantity),
							sdwan_quantity: parseInt(this.sdwanQuantity),
							content_filtering_quantity: parseInt(this.contentFilteringQuantity),
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("usage.licenses.action.add_license_success"));
							this.$router.push({ name: "Licenses" });
						})
						.catch((error) => {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									html:
										this.$t("usage.licenses.activate.error_content") +
										"<code>" +
										error.response.data.error +
										"</code>",
									footer: this.$t("responses.error.footer"),
									showCloseButton: false,
									icon: "error",
									allowOutsideClick: false,
									backdrop: `rgba(150,0,0,0.4)`,
								});
							}
							this.$router.push({ name: "Licenses" });
						});
				}
			});
		},
		formReset() {
			this.devicesQuantity = "";
			this.sdwanQuantity = "";
			this.contentFilteringQuantity = "";
			this.$emit("onFormReset");
			this.$router.push({ name: "Licenses" });
		},
	},
};
</script>
<style lang="scss" scoped>
form {
	@media screen and (min-width: 960px) {
		width: 45vw;
	}

	.mx-2 {
		margin-inline: 0.5rem;
	}

	.d-flex-justify-space-evenly {
		display: flex;
		justify-content: space-evenly;
	}
}
</style>
