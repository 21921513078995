<template>
	<md-card>
		<md-card-header>
			<h4 class="title">{{ tenant ? $t("tenants.edit_tenant.title") : $t("tenants.new_tenant.title") }}</h4>
		</md-card-header>
		<md-card-content>
			<ValidationObserver ref="formNewTenant">
				<form @submit="submitForm">
					<validation-provider v-slot="{ passed, failed }" name="tenant-name" rules="required">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>business</md-icon>
							<label>{{ $t("tenants.new_tenant.tenant_name") }}</label>
							<md-input
								v-if="customerRole != 'super-admin'"
								v-model="tenantName"
								type="text"
								readonly></md-input>
							<md-input v-else v-model="tenantName" type="text"></md-input>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="tenant-logo">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>image</md-icon>
							<label>{{ $t("tenants.new_tenant.tenant_logo") }}</label>
							<md-input v-model="tenantLogo" type="text"></md-input>
							<a :href="tenantLogo" target="_blank">
								<img :src="tenantLogo" alt="Logo" style="width: 50px; height: auto" />
							</a>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="tenant-domain">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>link</md-icon>
							<label>{{ $t("tenants.new_tenant.tenant_domain") }}</label>
							<md-input v-model="tenantDomain" type="text"></md-input>
						</md-field>
					</validation-provider>

					<validation-provider v-slot="{ passed, failed }" name="tenant-platform_name">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
							<md-icon>dns</md-icon>
							<label>{{ $t("tenants.new_tenant.tenant_platform_name") }}</label>
							<md-input v-model="tenantPlatformName" type="text"></md-input>
						</md-field>
					</validation-provider>

					<div class="d-flex-justify-space-evenly">
						<md-button class="md-success" type="submit"
							>{{ $t("tenants.new_tenant.submit_button") }}
						</md-button>
						<md-button class="md-danger" type="reset" @click="formReset"
							>{{ $t(`tenants.new_tenant.reset_button`) }}
						</md-button>
					</div>
				</form>
			</ValidationObserver>
		</md-card-content>
	</md-card>
</template>
<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { extend } from "vee-validate";
import { email, regex, required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";

extend("required", required);
extend("regex", regex);
extend("email", email);
extend("passwordRegex", {
	validate(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(value);
	},
	message: "fields.password.validation",
});

extend("requiredIfUser", {
	params: ["target"],
	validate(value, { target }) {
		return target.length > 0 && value == target;
	},
});

export default {
	name: "NewTenantForm",
	components: {},
	emits: ["onFormReset", "formSubmitted"],
	data() {
		return {
			customerRole: TokenService.getUser()["role"],
			tenantName: "",
			tenantLogo: "",
			tenantDomain: "",
			tenantPlatformName: "",
			user: {
				email: "",
				password: "",
			},
		};
	},
	props: {
		tenant: {
			type: Object,
			default: null,
		},
	},
	watch: {
		tenant: {
			immediate: true,
			handler(newTenant) {
				if (newTenant) {
					this.tenantName = newTenant.name;
					this.tenantLogo = newTenant.logo;
					this.tenantDomain = newTenant.domain;
					this.tenantPlatformName = newTenant.platform_name;
				}
			},
			deep: true,
		},
	},
	mounted() {
		let userData = TokenService.getUser();
	},
	methods: {
		notifyError(verticalAlign, horizontalAlign, message) {
			this.$notify({
				timeout: 5000,
				message: message,
				icon: "add_alert",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: "success",
			});
		},

		submitForm() {
			return this.$refs.formNewTenant.validate().then((res) => {
				if (res === false) return;

				if (this.tenant) {
					evoapi
						.put("/tenants/" + this.tenant.tenant_id, {
							name: this.tenantName,
							logo: this.tenantLogo,
							domain: this.tenantDomain,
							platform_name: this.tenantPlatformName,
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("tenants.list.action.edit_tenant_success"));
							this.$router.push({ name: "List Tenants" });
						})
						.catch((error) => {
							if (error.response) {
								if (error.response.status === 500 && error.response.data.error) {
									// Gestione dell'errore specifico restituito dall'API
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										html:
											this.$t("tenant.create.error.content") +
											"<code>" +
											error.response.data.error +
											"</code></small",
										footer: this.$t("responses.error.footer"),
										customClass: {
											confirmButton: "md-button md-success",
										},
										buttonsStyling: false,
									});
								} else {
									// Gestione di altri tipi di errori
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							}
						});
				} else {
					evoapi
						.post("/tenants/", {
							name: this.tenantName,
							logo: this.tenantLogo,
							domain: this.tenantDomain,
							platform_name: this.tenantPlatformName,
						})
						.then((response) => {
							this.notifyError("top", "right", this.$t("tenants.list.action.add_tenant_success"));
							this.$router.push({ name: "List Tenants" });
						})
						.catch((error) => {
							Swal.fire({
								icon: "error",
								title: this.$t("responses.error.title"),
								text: this.$t("responses.error.content"),
								footer: this.$t("responses.error.footer"),
							});
						});
				}
			});
		},

		formReset() {
			this.tenantName = "";
			this.tenantLogo = "";
			this.tenantDomain = "";
			this.tenantPlatformName = "";
			this.$emit("onFormReset");
			this.$router.push({ name: "List Tenants" });
		},
	},
};
</script>
<style lang="scss" scoped>
form {
	@media screen and (min-width: 960px) {
		width: 45vw;
	}

	.mx-2 {
		margin-inline: 0.5rem;
	}

	.d-flex-justify-space-evenly {
		display: flex;
		justify-content: space-evenly;
	}
}
</style>
