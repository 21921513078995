// store/auth.js
import AuthService from "../services/auth";
import TokenService from "../services/token";

const user = JSON.parse(localStorage.getItem("userData"));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		async login({ commit, dispatch }, credentials) {
			try {
				const user = await AuthService.login(credentials.email, credentials.password, credentials.otp_token);
				commit("loginSuccess", user);

				// Recupera i dati dell'utente dal localStorage
				let userData = TokenService.getUser();
				// console.log("User data from TokenService:", userData);

				// Se userData è vuoto, attendi un breve periodo di tempo e prova di nuovo
				if (!userData) {
					await new Promise((resolve) => setTimeout(resolve, 100));
					userData = TokenService.getUser();
					// console.log("Retry user data from TokenService:", userData);
				}

				if (userData) {
					const permissions = userData.permissions || [];
					// console.log("Permissions:", permissions);
					await dispatch("permissions/setPermissions", permissions, { root: true });
					return userData;
				} else {
					throw new Error("Failed to retrieve user data");
				}
			} catch (error) {
				// console.log("Login error:", error);
				commit("loginFailure");
				throw error;
			}
		},
		async logout({ commit }) {
			await AuthService.logout();
			commit("logout");
		},
		refreshToken({ commit }, accessToken) {
			commit("refreshToken", accessToken);
		},
		async initialize({ commit, dispatch }) {
			const user = TokenService.getUser();
			if (user) {
				commit("loginSuccess", user);
				const permissions = user.permissions || [];
				// console.log("Initializing permissions:", permissions);
				await dispatch("permissions/setPermissions", permissions, { root: true });
			}
		},
		async forgotPassword({ commit }, email) {
			try {
				const response = await AuthService.forgotPassword(email);
				// Puoi anche aggiungere una mutazione se vuoi aggiornare lo stato
				return Promise.resolve(response);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async resetPassword({ commit }, { token, password }) {
			try {
				const response = await AuthService.resetPassword({ token, password });
				return Promise.resolve(response);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async verifyResetToken({ commit }, token) {
			try {
				await AuthService.verifyResetToken(token);
			} catch (error) {
				throw error;
			}
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		refreshToken(state, accessToken) {
			state.status.loggedIn = true;
			state.user = { ...state.user, accessToken: accessToken };
		},
	},
};
