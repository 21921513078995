<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("notifications.title") }}</h4>
					<br />
					<div class="text-right">
						<md-button class="md-info md-round" @click.native="refreshList()">
							{{ $t("notifications.refresh_list") }}
							<md-icon>refresh</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<div class="table table-stats">
						<div class="text-right">
							<md-button class="md-success md-round" @click.native="acnknowledgeNotifications()">
								{{ $t("notifications.mark_all_as_read") }}

								<md-icon>start</md-icon>
							</md-button>
						</div>
					</div>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search_records')"
									v-model="searchQuery">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" :class="{ 'table-warning': !item.ack }" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('fields.started_at.label')"
								md-sort-by="insert_at"
								style="width: 10%; text-align: center"
								>{{ format_date(item.insert_at) }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.updated_at.label')"
								md-sort-by="update_at"
								style="width: 10%; text-align: center"
								>{{ format_date(item.update_at) }}</md-table-cell
							>
							<md-table-cell md-label="Module" md-sort-by="type" style="width: 10%; text-align: center">{{
								notificationTypes[item.type - 1]
							}}</md-table-cell>
							<md-table-cell :md-label="$t('fields.title.label')" md-sort-by="title" style="width: 70%">
								<div v-html="item.title"></div>
							</md-table-cell>
							<md-table-cell md-label="Status" md-sort-by="status" style="width: 10%; text-align: center">
								<span v-if="notificationStatuses[item.status - 1] === 'Ok'" class="status-ok">OK</span>
								<span v-if="notificationStatuses[item.status - 1] === 'Ko'" class="status-error"
									>ERROR</span
								>
								<span
									v-if="notificationStatuses[item.status - 1] === 'In Progress'"
									class="status-in-progress"
									>In Progress</span
								>
								<!-- {{
                notificationStatuses[item.status-1]
              }} -->
							</md-table-cell>
						</md-table-row>
					</md-table>
					<!-- <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.title"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div> -->
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
				<!-- <div class="table table-stats">
          <div class="text-right">
            <md-button class="md-success md-round" @click.native="acnknowledgeNotifications()">
              Acknowledge all notifications
              <md-icon>start</md-icon>
            </md-button>
          </div>
        </div> -->
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import moment from "moment";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	computed: {
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = [];
				this.searchedData.forEach((value, index) => {
					result.push(value.item);
				});
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "date",
			currentSortOrder: "desc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: ["Module", this.$t("fields.title.label"), "Status", this.$t("fields.date.label")],
			searchQuery: "",
			propsToSearch: ["title"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			notificationTypes: ["Provisioning", "Backup"],
			notificationStatuses: ["In Progress", "Ok", "Ko"],
		};
	},
	methods: {
		refreshList() {
			location.reload();
		},
		format_date(value) {
			if (value) {
				//moment.tz.guess(true);
				//return moment.utc(String(value)).format('DD/MM/YYYY HH:mm:ss')
				return moment(value).format("DD/MM/YYYY HH:mm:ss");
			}
		},
		acnknowledgeNotifications() {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/notifications/ack")
				.then(() => {
					this.getNotifications();
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				let one = "" + a[sortBy];
				let two = "" + b[sortBy];
				if (this.currentSortOrder === "desc") {
					return one.localeCompare(two);
				}
				return two.localeCompare(one);
			});
		},
		getNotifications() {
			// Get data from API
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + userData.customerId + "/notifications").then((response) => {
				this.tableData = response.data;
				// Fuse search initialization.
				this.fuseSearch = new Fuse(this.tableData, {
					keys: ["title"],
					threshold: 0.2,
				});
			});
		},
	},
	mounted() {
		this.getNotifications();
	},
	watch: {
		/**
		 * Searches through the table data by a given query.
		 * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
		 * @param value of the query
		 */
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
	},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.table-warning {
	background-color: #fff9c8 !important;
}
</style>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
}
.md-table .md-table-head:last-child {
	text-align: right;
}

.table-stats {
	display: flex;
	align-items: center;
	text-align: right;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}

.status-ok {
	font-weight: bold;
	color: green;
}

.status-error {
	font-weight: bold;
	color: red;
}

.status-in-progress {
	font-weight: bold;
	color: orange;
}
</style>
