import { render, staticRenderFns } from "./NewCustomerForm.vue?vue&type=template&id=b07b6dd8&scoped=true&"
import script from "./NewCustomerForm.vue?vue&type=script&lang=js&"
export * from "./NewCustomerForm.vue?vue&type=script&lang=js&"
import style0 from "./NewCustomerForm.vue?vue&type=style&index=0&id=b07b6dd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07b6dd8",
  null
  
)

export default component.exports