<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.infoTitle") }}</h4>
					<p v-html="$t('contentFiltering.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.list") }}</h4>
				</md-card-header>
				<md-card-content>
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click="$router.push({ name: 'Add Content Filtering' })"
							v-if="hasPermission('marketplace:content_filtering_add')">
							{{ $t("contentFiltering.add") }}
							<md-icon>add</md-icon>
						</md-button>
					</div>
					<md-table
						:md-sort-fn="customSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort.sync="currentSort"
						:value="queriedData"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-input
									v-model="filters.query"
									:placeholder="$t('general.search_records')"
									class="mb-3"
									clearable
									style="width: 200px"
									type="search">
								</md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('fields.device.id')"
								style="width: 5%"
								md-sort-by="device_id"
								class="centered-content bordered-cell"
								>{{ item.device_id }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.label')"
								style="width: 25%"
								md-sort-by="device_name"
								class="centered-content bordered-cell"
								>{{ item.device_name }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.service_entry')"
								style="width: 15%"
								md-sort-by="service_entry"
								class="centered-content bordered-cell"
								>{{ item.service_entry }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.service_type_name')"
								style="width: 25%"
								md-sort-by="service_type_name"
								class="centered-content bordered-cell">
								<b>{{ $t("fields.device.service_type_name") }}</b
								>: {{ item.service_type_name }} <br />
								<small><b>ID</b>: {{ item.service_type_id }}</small>
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								style="width: 30%"
								class="centered-content bordered-cell">
								<div>
									<!-- Upgrade-->
									<md-button
										class="md-just-icon md-simple md-medium-hide spacing-both md-primary"
										v-if="
											hasPermission('marketplace:content_filtering_edit') &&
											item.service_type_id === 1
										"
										@click.native="handleUpgrade(item.device_id)">
										<md-tooltip md-direction="top"
											><p v-html="$t('contentFiltering.upgradeTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>upgrade</md-icon>
											<div style="font-size: xx-small">Upgrade</div>
										</div>
									</md-button>
									<!-- Redeploy -->
									<md-button
										class="md-just-icon md-simple md-medium-hide spacing-both md-info"
										v-if="hasPermission('marketplace:content_filtering_edit')"
										@click.native="handleRedeploy(item)">
										<md-tooltip md-direction="top"
											><p v-html="$t('contentFiltering.redeployTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>refresh</md-icon>
											<div style="font-size: xx-small">Redeploy</div>
										</div>
									</md-button>
									<!-- Config -->
									<md-button
										class="md-just-icon md-simple md-medium-hide spacing-both md-warning"
										@click.native="handleEdit(item.device_id)"
										v-if="hasPermission('marketplace:content_filtering_edit')">
										<md-tooltip md-direction="top"
											><p v-html="$t('contentFiltering.editTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>dashboard</md-icon>
											<div style="font-size: xx-small">Config</div>
										</div>
									</md-button>
									<!-- Delete -->
									<md-button
										class="md-just-icon md-simple md-medium-hide spacing-both md-danger"
										@click.native="handleDelete(item)"
										v-if="hasPermission('marketplace:content_filtering_delete')">
										<md-tooltip md-direction="top"
											><p v-html="$t('contentFiltering.deleteTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>delete</md-icon>
											<div style="font-size: xx-small">Delete</div>
										</div>
									</md-button>
								</div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						class="pagination-no-border pagination-success">
					</pagination>
				</md-card-actions>
			</md-card>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>motion_photos_pause</md-icon>
					</div>
					<h4 class="title">{{ $t("contentFiltering.suspended") }}</h4>
				</md-card-header>
				<md-card-content>
					<md-table
						:md-sort-fn="customSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort.sync="currentSort"
						:value="suspendedData"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								:md-label="$t('fields.device.id')"
								style="width: 5%"
								md-sort-by="device_id"
								class="centered-content bordered-cell"
								>{{ item.device_id }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.label')"
								style="width: 25%"
								md-sort-by="device_name"
								class="centered-content bordered-cell"
								>{{ item.device_name }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.service_entry')"
								style="width: 15%"
								md-sort-by="service_entry"
								class="centered-content bordered-cell"
								>{{ item.service_entry }}
							</md-table-cell>
							<md-table-cell
								:md-label="$t('fields.device.service_type_name')"
								style="width: 25%"
								md-sort-by="service_type_name"
								class="centered-content bordered-cell">
								<b>{{ $t("fields.device.service_type_name") }}</b
								>: {{ item.service_type_name }} <br />
								<small><b>ID</b>: {{ item.service_type_id }}</small>
							</md-table-cell>
							<md-table-cell></md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						class="pagination-no-border pagination-success">
					</pagination>
				</md-card-actions>
			</md-card>
			<modal v-if="flgShowTemplateTextModal" @close="hideTemplateTextModal">
				<template slot="header">
					<h4 class="modal-title">{{ templateModal.title }}</h4>
					<md-button
						class="md-simple md-just-icon md-round modal-default-button"
						@click="hideTemplateTextModal">
						<md-icon>clear</md-icon>
					</md-button>
				</template>

				<template slot="body">
					<pre class="modal-code">{{ templateModal.text }}</pre>
				</template>

				<template slot="footer">
					<md-button class="md-info md-round" @click="editTemplate()">Edit</md-button>
					<md-button class="md-info md-round" @click="hideTemplateTextModal">Close</md-button>
				</template>
			</modal>
		</div>
	</div>
</template>

<script>
import { Modal, Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import TokenService from "@/services/token";
import { swalUtils } from "@/mixins/mixin";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	mixins: [swalUtils],
	components: {
		Pagination,
		Modal,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}
			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			if (!this.searchedData || !this.tableData) {
				return 0;
			}
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
		suspendedData() {
			let result = this.suspendedTableData;
			return result;
		},
	},
	data() {
		return {
			flgShowTemplateTextModal: false,
			templateModal: {
				title: "",
				text: "",
				template_id: 0,
			},
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: [this.$t("fields.description.label")],
			searchQuery: "",
			propsToSearch: ["description"],
			tableData: [],
			suspendedTableData: [],
			searchedData: [],
			fuseSearch: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		searchByQuery(results) {
			if (!results || this.filters.query === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["device_name", "device_id", "service_entry"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		handleEdit(device_id) {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/content-filtering/sso", {
					device_id: device_id,
				})
				.then((response) => {
					// handle
					// response is
					// console.log("Response: ", response.data.sso)
					let sso = response.data.sso;
					// urlencoding sso
					//sso = encodeURIComponent(sso);
					// console.log("Sso: ", sso)

					// now we have to set the URL "https://cloud.flashstart.com/?auth_token=sso" in the iframe
					// now we have to open in a new window https://cloud.flashstart.com/?auth_token=sso
					window.open("https://cloud.flashstart.com/?auth_token=" + sso, "_blank");
					// this.openIframe(sso);
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							html:
								this.$t("licence.activate.error.content") +
								"<code>" +
								error.response.data.error +
								"</code>",
							footer: this.$t("responses.error.footer"),
							showCloseButton: false,
							icon: "error",
							allowOutsideClick: false,
							backdrop: `rgba(150,0,0,0.4)`,
						});
					}
				});
		},
		handleRedeploy(item) {
			this.swalWarningDialog(
				this.$t("dialogs.redeploy_content_filtering_confirmation.title"),
				this.$t("dialogs.redeploy_content_filtering_confirmation.content"),
				this.$t("dialogs.redeploy_content_filtering_confirmation.ok"),
				this.$t("dialogs.redeploy_content_filtering_confirmation.cancel")
			).then((result) => {
				if (result.value) {
					let userData = TokenService.getUser();
					this.swalShowLoader(this.$t("dialogs.redeploy_content_filtering_confirmation.content"));
					evoapi
						.post("/customers/" + userData.customerId + "/content-filtering/redeploy", {
							device_id: item.device_id,
							service_type_id: item.service_type_id,
						})
						.then((response) => {
							this.swalSuccessDialog(
								this.$t("responses.redeploy_success.title"),
								this.$t("responses.redeploy_success.content")
							);
						})
						.catch((error) => {
							this.swalBasicErrorDialog();
						});
				}
			});
		},
		handleUpgrade(device_id) {
			this.swalInfoDialog(
				this.$t("responses.upgrade_success.title"),
				this.$t("responses.upgrade_success.content"),
				this.$t("responses.upgrade_success.ok")
			);
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleDelete(item) {
			this.swalWarningDialog(
				this.$t("dialogs.delete_content_filtering_confirmation.title"),
				this.$t("dialogs.delete_content_filtering_confirmation.content", { subject: item.device_id }),
				this.$t("dialogs.delete_content_filtering_confirmation.ok")
			).then((result) => {
				if (result.value) {
					let req = {
						device_id: item.device_id,
						service_type_id: item.service_type_id,
					};
					let userData = TokenService.getUser();
					this.swalShowLoader();
					evoapi
						.post("/customers/" + userData.customerId + "/content-filtering/deactivate", req)
						.then((response) => {
							this.deleteRow(item);
							this.filters.query = ""; // Resetta il filtro di ricerca
							this.swalSuccessDialog(
								this.$t("responses.deleted_success.title"),
								this.$t("responses.deleted_success.content", { subject: item.device_id })
							).then((result) => {
								// Ricarica la pagina quando l'utente clicca sul pulsante di conferma
								if (result.value) {
									// window.location.reload();
									this.reloadTableData();
									this.reloadSuspendendTableData();
								}
							});
						})
						.catch((error) => {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("contentFiltering.error.correctRouter"),
									footer: this.$t("responses.error.footer"),
								});
							}
						});
				}
			});
		},
		openIframe(sso) {
			let userData = TokenService.getUser();
			this.$router.push({
				name: "FlashStart Web Panel",
				params: {
					sso: sso,
				},
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.device_id === item.device_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
				// Aggiorna queriedData o ricarica i dati dal server se necessario
				this.reloadTableData(); // Chiamalo solo se vuoi ricaricare i dati dal server
			}
		},
		async reloadTableData() {
			// Get data from API
			let userData = TokenService.getUser();
			let response = await evoapi.get("/customers/" + userData.customerId + "/content-filtering/list");
			this.tableData = response.data;
			this.searchedData = this.tableData;
		},
		async reloadSuspendendTableData() {
			// Get data from API
			let userData = TokenService.getUser();
			let response = await evoapi.get(
				"/customers/" + userData.customerId + "/content-filtering/list?suspended=1"
			);
			this.suspendedTableData = response.data;
		},
	},
	mounted() {
		this.reloadTableData();
		this.reloadSuspendendTableData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;
				results = this.searchByQuery(results);
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.icon-online {
	color: #4caf50 !important;
}
.icon-warning {
	color: #ff9800 !important;
}
.icon-danger {
	color: #dc3545 !important;
}
.button-warning {
	background-color: #ff9800 !important;
}
</style>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
	overflow-x: hidden;
}

.paginated-table.md-table {
	padding: 20px 20px 20px 20px;
}

.md-table {
	overflow-x: hidden;
	text-align: center;
}

.md-table-head {
	text-align: center;
	font-size: 20px;
}

.md-table .md-table-head:last-child {
	text-align: center;
	overflow-x: hidden;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-table-head-container {
	text-align: center;
}

// .paginated-table {
// 	text-align: center;
// }
// .md-ripple {
// 	text-align: center;
// }

.table-stats {
	display: flex;
	align-items: center;
	text-align: right;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}

.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}

.copy-paste-password {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: large;
	text-align: center;
}

.md-button.spacing-both {
	margin-left: 5px;
	margin-right: 5px;
}

.clipboard-tooltip {
	position: absolute;
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	/* ... altri stili per il tooltip ... */
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}

@media only screen and (min-width: 1281px) {
	.hide-on-large {
		display: none !important;
	}
}
</style>
