<template>
	<ValidationObserver v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(submit)">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-text">
						<h4 class="title">{{ $t("elasticIp.new.title") }}</h4>
					</div>
				</md-card-header>

				<md-card-content>
					<p v-html="$t('elasticIp.new.description')"></p>
					<md-field>
						<label>{{ $t("elasticIp.region") }}</label>
						<md-select v-model="selectedRegion">
							<md-option v-for="region in regions" :key="region.region_id" :value="region.region_id">
								{{ region.name }}
							</md-option>
						</md-select>
					</md-field>
					<!-- Selectbox per Subnet se la regione è selezionata -->
					<md-field v-if="selectedRegion">
						<label>{{ $t("elasticIp.subnet") }}</label>
						<md-select v-model="selectedSubnet">
							<md-option
								v-for="subnet in subnets"
								:key="subnet.elastic_ip_id"
								:value="subnet.elastic_ip_id">
								{{ subnet.subnet }}
							</md-option>
							<!-- Aggiunte opzioni fisse per /30 e /29 -->
							<md-option value="request_30">Request a /30</md-option>
							<md-option value="request_29">Request a /29</md-option>
						</md-select>
					</md-field>
				</md-card-content>

				<md-card-actions>
					<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
				</md-card-actions>
			</md-card>
		</form>
	</ValidationObserver>
</template>
<script>
// import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	// components: {
	//   SlideYDownTransition,
	// },
	data() {
		return {
			name: null,
			default_pwd: null,
			regions: [], // Aggiunto per le regioni
			selectedRegion: null, // Per mantenere la regione selezionata
			subnets: [], // Aggiunto per le subnet
			selectedSubnet: null, // Per mantenere la subnet selezionata
		};
	},
	created() {
		this.fetchRegions(); // Chiamata alla creazione del componente
	},
	watch: {
		selectedRegion(newVal) {
			// Resetta la selezione della subnet ogni volta che cambia la regione
			this.selectedSubnet = null;

			if (newVal) {
				this.fetchSubnets(newVal);
			} else {
				this.subnets = [];
			}
		},
	},
	methods: {
		fetchRegions() {
			let userData = TokenService.getUser();
			evoapi
				.get(`/customers/${userData.customerId}/elasticIp/listRegions`)
				.then((response) => {
					this.regions = response.data.regions;
				})
				.catch((error) => {
					// Gestione dell'errore
					// console.error("Errore nel recupero delle regioni:", error);
				});
		},
		// Metodo per ottenere le subnet basate sulla regione selezionata
		fetchSubnets(regionId) {
			let userData = TokenService.getUser();
			evoapi
				.get(`/customers/${userData.customerId}/elasticIp/listIps/${regionId}?onlyFree=true&onlyMine=false`)
				.then((response) => {
					this.subnets = response.data.ips; // Assicurati che questo corrisponda al formato della tua risposta API
				})
				.catch((error) => {
					// Gestione dell'errore
					// console.error("Errore nel recupero delle subnet:", error);
				});
		},
		submit() {
			let userData = TokenService.getUser();

			// Controllo se la subnet selezionata non è una delle richieste speciali
			if (this.selectedSubnet !== "request_30" && this.selectedSubnet !== "request_29") {
				// Effettua una chiamata GET per riservare il primo IP libero
				evoapi
					.get(`/customers/${userData.customerId}/elasticIp/reserve/${this.selectedSubnet}`)
					.then((response) => {
						this.$router.push({ name: "List Elastic IPs" });
					})
					.catch((error) => {
						if (error.response) {
							Swal.fire({
								icon: "error",
								title: this.$t("responses.error.title"),
								text: this.$t("responses.error.content"),
								footer: this.$t("responses.error.footer"),
							});
						}
					});
			} else {
				// Gestione delle richieste speciali di /30 o /29
				let subnetSize = this.selectedSubnet === "request_30" ? 30 : 29;
				evoapi
					.get(
						`/customers/${userData.customerId}/elasticIp/subnetRequest/${this.selectedRegion}/${subnetSize}`
					)
					.then((response) => {
						// Gestisci qui la risposta di successo
						Swal.fire({
							icon: "success",
							title: this.$t("responses.success.title"),
							text: this.$t("responses.success.subnet_reserved", { subject: `Subnet /${subnetSize}` }),
							customClass: {
								confirmButton: "md-button md-success",
							},
							buttonsStyling: false,
						});
						this.$router.push({ name: "List Elastic IPs" });
					})
					.catch((error) => {
						// Gestisci qui gli errori
						if (error.response) {
							Swal.fire({
								icon: "error",
								title: this.$t("responses.error.title"),
								text: this.$t("responses.error.content"),
								footer: this.$t("responses.error.footer"),
							});
						}
					});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
	border: none;
}
</style>
