<template>
	<div>
		<h5 class="info-text">
			{{ $t("wizard_sdwan.step_3.title") }}
			<md-button class="md-icon-button md-raised md-success" @click.native="addGateway()">
				<md-icon>add</md-icon>
			</md-button>
		</h5>
		<div class="md-layout">
			<div class="md-layout-item md-size-50 ml-auto mt-4 md-small-size-100">
				<md-list>
					<md-list-item v-for="(gateway, i) in gateways" :key="i">
						<span class="md-list-item-text">{{ gateway.gateway }}</span>
						<md-button class="md-icon-button md-list-action" @click.native="editGateway(gateway)">
							<md-icon>edit</md-icon>
						</md-button>
						<md-button class="md-icon-button md-list-action" @click.native="deleteGateway(gateway)">
							<md-icon>delete</md-icon>
						</md-button>
					</md-list-item>
				</md-list>
			</div>
			<div class="md-layout-item md-size-50 ml-auto mt-4 md-small-size-100">
				<gateway
					v-if="showGatewayEdit"
					:gatewayToEdit="gatewayToEdit"
					@gatewaySaved="gatewaySavedHandler"
					@hideGatewayEdit="hideGatewayEditHandler"></gateway>
			</div>
		</div>
	</div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Gateway from "../Gateway.vue";

extend("required", required);

export default {
	components: {
		Gateway,
	},
	data() {
		return {
			gateways: [],
			showGatewayEdit: false,
			gatewayToEdit: null,
		};
	},
	watch: {
		template_id: function () {
			if (this.template_id > 0) {
				let userData = TokenService.getUser();
				evoapi.get("/customers/" + userData.customerId + "/templates/" + this.template_id).then((response) => {
					this.template = response.data;
					this.flgShowTemplateVariables = this.template.variables.length > 0;
				});
			} else {
				this.flgShowTemplateVariables = false;
			}
		},
	},
	methods: {
		validate() {
			if (this.showGatewayEdit == false && this.gateways.length > 1) {
				this.$emit("on-validated", true);
			}
		},
		deleteGateway(gateway) {
			this.gateways = this.gateways.filter((item) => item.gateway !== gateway.gateway);
		},
		addGateway() {
			this.gatewayToEdit = null;
			this.showGatewayEdit = true;
		},
		editGateway(gateway) {
			let gw = JSON.stringify(gateway);
			this.gatewayToEdit = JSON.parse(gw);
			this.showGatewayEdit = true;
		},
		gatewaySavedHandler(gateway) {
			this.deleteGateway(gateway);
			this.gateways.push(gateway);
			this.showGatewayEdit = false;
		},
		hideGatewayEditHandler() {
			this.showGatewayEdit = false;
			this.gatewayToEdit = null;
		},
	},
};
</script>
<style lang="scss" scoped>
.md-list {
	width: 320px;
	max-width: 100%;
	display: inline-block;
	vertical-align: top;
	border: 1px solid rgba(#000, 0.12);
}
.md-list-item-text {
	position: relative !important;
}
</style>
