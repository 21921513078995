<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<template slot="header">
					<h3 class="title">{{ pageTitle }}</h3>
					<h5 class="category">Utilizza questo wizard per configurare il servizio SDWAN</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label"> Cpe </template>
					<first-step ref="step1" @on-validated="onStepValidated"></first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label"> Dati generici </template>
					<second-step ref="step2" @on-validated="onStepValidated"></second-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step3')">
					<template slot="label"> Gateways </template>
					<third-step ref="step3" @on-validated="wizardComplete" :provisionedSDWAN="false"></third-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from "./WizardSteps/FirstStep.vue";
import SecondStep from "./WizardSteps/SecondStep.vue";
import ThirdStep from "./WizardSteps/ThirdStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	data() {
		return {
			wizardModel: {},
			editedSDWAN: false,
			sdwanRegions: [],
		};
	},
	props: ["pageTitle", "sdwanConnection"],
	components: {
		FirstStep,
		SecondStep,
		ThirdStep,
		SimpleWizard,
		WizardTab,
	},
	mounted() {
		if (this.sdwanConnection) {
			this.$refs.step1.cpe = this.sdwanConnection.cpe;
			this.$refs.step2.contract_id = this.sdwanConnection.contract_id;
			this.$refs.step2.description = this.sdwanConnection.description;
			this.$refs.step3.gateways = this.sdwanConnection.gateways;
			this.editedSDWAN = true;
			this.loadSdwanRegions(); // Carica le regioni SDWAN
		}
	},
	methods: {
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		async wizardComplete() {
			let userData = TokenService.getUser();
			let req = {
				contract_id: parseInt(this.$refs.step2.contract_id),
				description: this.$refs.step2.description,
				cpe: this.$refs.step1.cpe,
				gateways: this.$refs.step3.gateways,
				sdwanType: this.$refs.step1.sdwanType,
				lan_network: this.$refs.step1.lan_network,
			};

			Swal.fire({
				title: this.$t("dialogs.configuring_sdwan.title"),
				html: this.$t("dialogs.configuring_sdwan.content"),
				allowEscapeKey: false,
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
					// Editing case
					if (this.editedSDWAN) {
						(req.contract_id = parseInt(this.$refs.step1.contract_id)),
							evoapi
								.put("/customers/" + userData.customerId + "/sdwan/config/" + req.contract_id, req)
								.then((response) => {
									const shieldError =
										(this.gatewayShield && response["data"]["shieldError"]) ?? false;
									Swal.close();
									if (response.status === 200) {
										Swal.fire({
											title: this.$t("dialogs.update_sdwan_completed.title"),
											text: this.$t("dialogs.update_sdwan_completed.content"),
											buttonsStyling: false,
											confirmButtonClass: "md-button md-success",
											icon: "success",
											footer: shieldError ? this.$t("responses.error.footer") : "",
										});
										this.$router.push({ name: "List SDWAN" });
									} else {
										throw new Error("Device not available");
									}
								})
								.catch((error) => {
									if (error.response.data.error === "Local preference must be between 107 and 110") {
										Swal.fire({
											title: this.$t("dialogs.update_sdwan_failed.title"),
											html: this.$t("dialogs.update_sdwan_failed.content_local_pref"),
											confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
											showCloseButton: false,
											icon: "error",
											allowOutsideClick: false,
											backdrop: `rgba(150,0,0,0.4)`,
										}).then(() => {
											// this.fetchData();
										});
									} else {
										Swal.fire({
											title: this.$t("dialogs.update_sdwan_failed.title"),
											html: this.$t("dialogs.update_sdwan_failed.content"),
											confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
											showCloseButton: false,
											icon: "error",
											allowOutsideClick: false,
											backdrop: `rgba(150,0,0,0.4)`,
										}).then(() => {
											// this.fetchData();
										});
									}
								});
					}
					// Adding case
					else {
						evoapi
							.post("/customers/" + userData.customerId + "/sdwan/config", req)
							.then((response) => {
								Swal.close();
								Swal.fire({
									title: this.$t("dialogs.create_sdwan_completed.title"),
									text: this.$t("dialogs.create_sdwan_completed.content"),
									buttonsStyling: false,
									confirmButtonClass: "md-button md-success",
									icon: "success",
								});
								this.$router.push({ name: "List SDWAN" });
							})
							.catch(function (error) {
								if (error.response) {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								} else if (
									error.response.data.error === "Local preference must be between 107 and 110"
								) {
									Swal.fire({
										title: this.$t("dialogs.update_sdwan_failed.title"),
										html: this.$t("dialogs.update_sdwan_failed.content_local_pref"),
										confirmButtonText: this.$t("dialogs.update_sdwan_failed.ok"),
										showCloseButton: false,
										icon: "error",
										allowOutsideClick: false,
										backdrop: `rgba(150,0,0,0.4)`,
									});
								} else {
									// console.log(error);
								}
							});
					}
				},
			});
		},
	},
	loadSdwanRegions() {
		let userData = TokenService.getUser();
		evoapi
			.get(`/customers/${userData.customerId}/elasticIp/listRegions`)
			.then((response) => {
				this.sdwanRegions = response.data;
				// logging the response
				// console.log(response.data);
			})
			.catch((error) => {
				// console.error("Errore nel caricamento delle regioni SDWAN", error);
				// Gestire l'errore come necessario
			});
	},
};
</script>
