<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("provisioner.infoTitle") }}</h4>
					<p v-html="$t('provisioner.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("provisioner.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click.native="addProvisionerRequest()"
							v-if="hasPermission('provision:provision_add')">
							{{ $t("provisioner.add") }}
							<md-icon>add</md-icon>
						</md-button>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search')"
									v-model="filters.query">
								</md-input>
							</md-field>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell
								md-label="ID"
								md-sort-by="provisioner_id"
								style="width: 5%"
								class="centered-content bordered-cell"
								>{{ item.provisioner_id }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.name.label')"
								md-sort-by="name"
								style="width: 50%"
								class="centered-content bordered-cell"
								>{{ item.name }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								style="width: 45%"
								class="centered-content bordered-cell">
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-success"
									@click.native="showNeighbours(item.provisioner_id)"
									v-if="hasPermission('provision:provision_device_scan')">
									<md-tooltip md-direction="top"
										><p v-html="$t('provisioner.neighboursTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>location_searching</md-icon>
										<div style="font-size: xx-small">DEVICES</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-warning"
									@click.native="handleUpdateName(item)"
									v-if="hasPermission('provision:provision_edit')">
									<md-tooltip md-direction="top"
										><p v-html="$t('provisioner.editTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>edit</md-icon>
										<div style="font-size: xx-small">EDIT</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-info"
									@click.native="handleGetCommands(item)"
									v-if="hasPermission('provision:provision_onboard')">
									<md-tooltip md-direction="top"
										><p v-html="$t('provisioner.oboardingTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>keyboard_command_key</md-icon>
										<div style="font-size: xx-small">ONBOARD</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-danger"
									@click.native="handleDelete(item)"
									v-if="hasPermission('provision:provision_delete')">
									<md-tooltip md-direction="top"
										><p v-html="$t('provisioner.deleteTooltip')"></p
									></md-tooltip>
									<div class="icon-with-label">
										<md-icon>delete</md-icon>
										<div style="font-size: xx-small">DELETE</div>
									</div>
								</md-button>
								<!-- We have to leave this empty div as separator -->
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
	components: {
		Pagination,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	data() {
		return {
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: ["Id", this.$t("fields.name.label")],
			searchQuery: "",
			propsToSearch: ["name"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			timer: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		handleUpdateName(item) {
			const { value: deviceName } = Swal.fire({
				title: this.$t("dialogs.provisioners_edit.title"),
				input: "text",
				inputPlaceholder: this.$t("dialogs.provisioners_edit.placeholder"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.provisioners_edit.validation");
					}
				},
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.provisioners_edit.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					let userData = TokenService.getUser();
					evoapi
						.put(
							"/customers/" +
								userData.customerId +
								"/provisioners/" +
								item.provisioner_id +
								"/updateName",
							{
								name: result.value,
							}
						)
						.then((response) => {
							Swal.fire({
								title: this.$t("responses.provisioners_edit_success.title"),
								html: this.$t("responses.provisioners_edit_success.content"),
								buttonsStyling: false,
								confirmButtonClass: "md-button md-success",
								icon: "success",
							});
							let indexToDelete = this.tableData.findIndex(
								(tableRow) => tableRow.provisioner_id === item.provisioner_id
							);
							this.tableData[indexToDelete].name = result.value;
						})
						.catch(function (error) {
							if (error.response) {
								Swal.fire({
									icon: "error",
									title: this.$t("responses.error.title"),
									text: this.$t("responses.error.content"),
									footer: this.$t("responses.error.footer"),
								});
							} else {
								// console.log(error);
							}
						});
				}
			});
		},
		handleGetCommands(item) {
			let userData = TokenService.getUser();
			evoapi
				.get(
					"/customers/" + userData.customerId + "/provisioners/" + item.provisioner_id + "/onboardingCommands"
				)
				.then((response) => {
					let commands = response.data.join("<br><br>");
					Swal.fire({
						title: this.$t("dialogs.provisioners_commands.title"),
						html: this.$t("dialogs.provisioners_commands.content", { commands }),
						// buttonsStyling: false,
						// confirmButtonClass: "md-button md-success",
						confirmButtonText: this.$t("dialogs.provisioners_commands.ok"),
						showCloseButton: true,
						icon: "success",
						allowOutsideClick: false,
						backdrop: `rgba(0,0,123,0.4)`,
					}).then((result) => {
						if (result.isConfirmed) {
							if (window.clipboardData && window.clipboardData.setData) {
								// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
								return window.clipboardData.setData("Text", commands);
							} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
								var textarea = document.createElement("textarea");
								textarea.textContent = commands;
								textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
								document.body.appendChild(textarea);
								textarea.select();
								try {
									return document.execCommand("copy"); // Security exception may be thrown by some browsers.
								} catch (ex) {
								} finally {
									document.body.removeChild(textarea);
								}
							}
						}
					});
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					} else {
						// console.log(error);
					}
				});
		},
		handleDelete(item) {
			Swal.fire({
				title: this.$t("dialogs.provisioners_delete_confirmation.title"),
				text: this.$t("dialogs.provisioners_delete_confirmation.content", { subject: item.name }),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.provisioners_delete_confirmation.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					var closeInSeconds = 30,
						displayText = this.$t("dialogs.provisioners_delete_confirmation.waiting");
					Swal.fire({
						title: this.$t("dialogs.provisioners_delete_progress.title"),
						html: displayText.replace(/#1/, closeInSeconds),
						timer: (closeInSeconds + 5) * 1000,
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					this.timer = setInterval(function () {
						closeInSeconds--;
						if (closeInSeconds == 0) {
							clearInterval(this.timer);
						}
						const sat = document.getElementById("swal2-html-container");
						if (sat && sat.innerHTML) {
							sat.innerHTML = displayText.replace(/#1/, closeInSeconds);
						}
					}, 1000);

					let userData = TokenService.getUser();
					evoapi
						.delete(
							"/customers/" +
								userData.customerId +
								"/provisioners/" +
								item.provisioner_id +
								"?byPass=false"
						)
						.then((response) => {
							clearInterval(this.timer);
							this.deleteRow(item);
							Swal.fire({
								title: this.$t("responses.deleted_success.title"),
								html: this.$t("responses.deleted_success.content", { subject: item.name }),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							});
						})
						.catch((error) => {
							clearInterval(this.timer);
							if (error.response) {
								if (error.response.status == 503) {
									Swal.fire({
										title: "Oops...",
										text: this.$t("responses.provisioning_delete_error.content"),
										icon: "warning",
										showCancelButton: true,
										customClass: {
											confirmButton: "md-button md-success",
											cancelButton: "md-button md-danger btn-fill",
										},
										cancelButtonText: this.$t("general.cancel"),
										confirmButtonText: this.$t("dialogs.provisioners_delete_confirmation.ok"),
										buttonsStyling: false,
									}).then((result) => {
										if (result.value) {
											Swal.fire({
												title: this.$t("dialogs.provisioners_delete_progress.title"),
												html: this.$t("dialogs.provisioners_delete_progress.content"),
												allowEscapeKey: false,
												allowOutsideClick: false,
												didOpen: () => {
													Swal.showLoading();
												},
											});
											evoapi
												.delete(
													"/customers/" +
														userData.customerId +
														"/provisioners/" +
														item.provisioner_id +
														"?byPass=true"
												)
												.then((response) => {
													Swal.fire({
														title: "Attività completata!",
														text: `Hai eliminato ${item.name}`,
														icon: "success",
														customClass: {
															confirmButton: "md-button md-success",
														},
														buttonsStyling: false,
													});
													this.deleteRow(item);
												})
												.catch(function (error) {
													Swal.fire({
														icon: "error",
														title: this.$t("responses.error.title"),
														text: this.$t("responses.error.content"),
														footer: this.$t("responses.error.footer"),
													});
												});
										}
									});
								} else {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							} else {
								// console.log(error);
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.provisioner_id === item.provisioner_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},
		showNeighbours(provisioner_id) {
			this.$router.push({ name: "List Neighbour Devices", params: { provisioner_id: provisioner_id } });
		},
		addProvisionerRequest() {
			this.$router.push({ name: "Add Provisioner" });
		},
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["name"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			let userData = TokenService.getUser();
			await evoapi.get("/customers/" + userData.customerId + "/provisioners").then((response) => {
				this.tableData = response.data;
				this.searchedData = this.tableData;
			});
		},
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				//console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>

<style lang="css" scoped>
.md-table-head {
	text-align: center;
	font-size: 20px;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}
</style>
