<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<new-elastic-ip-form></new-elastic-ip-form>
		</div>
	</div>
</template>
<script>
import NewElasticIpForm from "./NewElasticIpForm.vue";

export default {
	components: {
		NewElasticIpForm,
	},
};
</script>
