<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text">
					{{ $t("wizard_provisioning.step_1_new.title") }}
				</h5>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>badge</md-icon>
								<label>{{ $t("fields.name.label") }}</label>
								<md-input v-model="name" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>

						<ValidationProvider name="device_groups" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>group</md-icon>
								<label>{{ $t("fields.device_groups.label") }}</label>
								<md-select v-model="deviceGroups" name="deviceGroups" id="deviceGroups" multiple>
									<md-option
										v-for="item in listDeviceGroups"
										:value="item.device_group_id"
										:key="item.device_group_id"
										>{{ item.name }}</md-option
									>
								</md-select>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>

						<ValidationProvider
							name="device_groups"
							v-slot="{ passed, failed }"
							v-if="listGroupsWithPassword.length > 0">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>password</md-icon>
								<label>{{ $t("fields.device_group_password.label") }}</label>
								<md-select
									v-model="deviceGroupPassword"
									name="deviceGroupPassword"
									id="deviceGroupPassword">
									<md-option
										v-for="item in listGroupsWithPassword"
										:value="item.device_group_id"
										:key="item.device_group_id"
										>{{ item.name }}</md-option
									>
								</md-select>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
					</div>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			name: "",
			deviceGroups: [],
			listDeviceGroups: [],
			listGroupsWithPassword: [],
			deviceGroupPassword: 0,
		};
	},
	mounted() {
		// Get data from API
		let userData = TokenService.getUser();
		evoapi.get("/customers/" + userData.customerId + "/device-groups").then((response) => {
			this.listDeviceGroups = response.data;
		});
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
	},
	watch: {
		deviceGroups: function () {
			var dgs = this.deviceGroups;
			var groupsWithPwd = this.listDeviceGroups.filter(function (elem) {
				if (elem.has_default_pwd && dgs.includes(elem.device_group_id)) return elem;
			});
			this.listGroupsWithPassword = groupsWithPwd;
			if (this.listGroupsWithPassword == 0) {
				this.deviceGroupPassword = 0;
			} else {
				if (!this.listGroupsWithPassword.includes(this.deviceGroupPassword)) {
					this.deviceGroupPassword = 0;
				}
			}
		},
	},
};
</script>
<style>
.md-select-value {
	margin-left: 13px !important;
}
</style>
