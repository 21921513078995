<template>
	<ValidationObserver v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(submit)">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-text">
						<h4 class="title">{{ $t("deviceGroup.new") }}</h4>
					</div>
				</md-card-header>

				<md-card-content>
					<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("deviceGroup.name") }}</label>
							<md-input v-model="name"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>

					<ValidationProvider name="default_pwd" v-slot="{ passed, failed }">
						<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
							<label>{{ $t("deviceGroup.password") }}</label>
							<md-input v-model="default_pwd"> </md-input>

							<slide-y-down-transition>
								<md-icon class="error" v-show="failed">close</md-icon>
							</slide-y-down-transition>
							<slide-y-down-transition>
								<md-icon class="success" v-show="passed">done</md-icon>
							</slide-y-down-transition>
						</md-field>
					</ValidationProvider>
				</md-card-content>

				<md-card-actions>
					<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
				</md-card-actions>
			</md-card>
		</form>
	</ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			name: null,
			default_pwd: null,
		};
	},
	methods: {
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/device-groups", {
					name: this.name,
					default_pwd: this.default_pwd,
				})
				.then((response) => {
					this.$router.push({ name: "List device groups" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
	border: none;
}
</style>
