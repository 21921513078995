var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [(_vm.isLoading)?_c('div',{staticClass:"loading-overlay"},[_c('div',{staticClass:"loading-container"},[_c('img',{staticClass:"loading-animation",attrs:{"src":"img/loading.gif","alt":"Loading","width":"200"}})])]):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-green"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("contentFiltering.new")))])]),_c('br'),_c('br')]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',{staticClass:"md-no-border"},[_c('md-autocomplete',{attrs:{"md-options":_vm.contentFilteringVersions.map((contentFilteringVersion) => ({
										value: contentFilteringVersion.id,
										label: contentFilteringVersion.name,
										toLowerCase: () => contentFilteringVersion.name.toLowerCase(),
										toString: () => contentFilteringVersion.name,
									}))},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function({ item }){return [_vm._v(_vm._s(item.label))]}}],null,true),model:{value:(_vm.selectedContentFilteringVersions),callback:function ($$v) {_vm.selectedContentFilteringVersions=$$v},expression:"selectedContentFilteringVersions"}},[_c('label',[_vm._v(_vm._s(_vm.$t("contentFiltering.selectContentFilteringVersions")))]),_c('template',{slot:"md-autocomplete-empty"},[_c('b',[_vm._v(_vm._s(_vm.$t("contentFiltering.noRoutersFound")))])])],2)],1)],1)])]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100"},[_c('md-field',{staticClass:"md-no-border"},[_c('md-autocomplete',{attrs:{"md-options":_vm.routers.map((router) => ({
										value: router.id,
										label: router.name,
										toLowerCase: () => router.name.toLowerCase(),
										toString: () => router.name,
									}))},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function({ item }){return [_vm._v(_vm._s(item.label))]}}],null,true),model:{value:(_vm.selectedRouter),callback:function ($$v) {_vm.selectedRouter=$$v},expression:"selectedRouter"}},[_c('label',[_vm._v(_vm._s(_vm.$t("contentFiltering.selectRouter")))]),_c('template',{slot:"md-autocomplete-empty"},[_c('b',[_vm._v(_vm._s(_vm.$t("contentFiltering.noRoutersFound")))])])],2)],1)],1)])]),_c('md-card-actions',[_c('md-button',{staticClass:"md-back",attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v(_vm._s(_vm.$t("general.close")))]),_c('md-button',{staticClass:"md-success",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("general.save")))])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }