<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text">
					{{ $t("wizard_provisioning.step_2_new.title") }}
				</h5>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<ValidationProvider name="username" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>account_circle</md-icon>
								<label>Username</label>
								<md-input v-model="username" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
						<ValidationProvider name="password" v-slot="{ passed, failed }">
							<md-field
								:class="[{ 'md-error': failed }, { 'md-valid': passed }, { 'md-form-group': true }]">
								<md-icon>key</md-icon>
								<label>Password</label>
								<md-input v-model="password" type="text"> </md-input>

								<slide-y-down-transition>
									<md-icon class="error" v-show="failed">close</md-icon>
								</slide-y-down-transition>
								<slide-y-down-transition>
									<md-icon class="success" v-show="passed">done</md-icon>
								</slide-y-down-transition>
							</md-field>
						</ValidationProvider>
					</div>
				</div>
				<div class="md-layout">
					<div class="md-layout-item md-size-100 ml-auto mt-4 md-small-size-100">
						<md-checkbox v-model="toReset">{{ $t("wizard_provisioning.step_2_new.cta") }}</md-checkbox>
					</div>
				</div>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { SlideYDownTransition } from "vue2-transitions";

extend("required", required);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			username: "",
			password: "",
			toReset: false,
		};
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
	},
};
</script>
<style></style>
