<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("backups.title") }}</h4>
					<br />
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover">
						<md-table-toolbar>
							<md-field>
								<label for="pages">{{ $t("general.perPage") }}</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input
									type="search"
									class="mb-3"
									clearable
									style="width: 200px"
									:placeholder="$t('general.search_records')"
									v-model="filters.query">
								</md-input>
							</md-field>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }" md-auto-select>
							<md-table-cell
								:md-label="$t('fields.file_date.label')"
								md-sort-by="file_date"
								style="width: 20%"
								>{{ item.file_date }}</md-table-cell
							>
							<md-table-cell
								:md-label="$t('fields.file_name.label')"
								md-sort-by="file_name"
								style="width: 50%"
								>{{ item.file_name }}</md-table-cell
							>
							<md-table-cell :md-label="$t('fields.actions.label')" style="width: 30%">
								<!-- We have to leave this empty div as separator -->
								<div></div>
								<md-button
									class="md-just-icon md-simple md-success"
									@click.native="downloadBackup(item)"
									v-if="hasPermission('backup:backup_show_download')">
									<md-tooltip md-direction="top">
										{{ $t("devices_backup.list.download_cta") }}
									</md-tooltip>
									<div class="icon-with-label">
										<md-icon>cloud_download</md-icon>
										<div style="font-size: xx-small">DOWNLOAD</div>
									</div>
								</md-button>
								<div></div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to: to, total: total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		/***
		 * Returns a page from the searched data or the whole data. Search is performed in the watch section below
		 */
		queriedData() {
			if (this.searchedData.length === 0) {
				return [];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
	},
	props: ["device_id"],
	data() {
		return {
			currentSort: "file_date",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500],
				total: 0,
			},
			footerTable: [this.$t("fields.file_name.label"), this.$t("fields.file_date.label")],
			searchQuery: "",
			propsToSearch: ["file_name", "file_date"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			filters: {
				query: "",
			},
		};
	},
	methods: {
		downloadBackup(item) {
			let userData = TokenService.getUser();
			evoapi
				.post(
					"/customers/" + userData.customerId + "/backups/devices/" + this.device_id,
					{ backup_name: item.file_name },
					{ responseType: "blob" }
				)
				.then((response) => {
					if (!window.navigator.msSaveOrOpenBlob) {
						// BLOB NAVIGATOR
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", item.file_name);
						document.body.appendChild(link);
						link.click();
					} else {
						window.navigator.msSaveOrOpenBlob(new Blob([response.data]), item.file_name);
					}
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		searchByQuery(results) {
			if (this.filters.query === "") {
				return results;
			}

			this.fuseSearch = new Fuse(results, {
				keys: ["file_name", "file_date"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});

			return this.fuseSearch.search(this.filters.query).map((el) => el.item);
		},
		async fetchData() {
			let userData = TokenService.getUser();
			let response = await evoapi.get("/customers/" + userData.customerId + "/backups/devices/" + this.device_id);
			this.tableData = response.data;
			this.searchedData = this.tableData;
		},
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		filters: {
			handler: function (value) {
				let results = this.tableData;

				// Fuse search initialization.
				results = this.searchByQuery(results);

				//console.log('RESULTS', results)
				this.searchedData = results;
			},
			deep: true,
		},
	},
};
</script>
