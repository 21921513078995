<!-- questo è la selezione del template per i device onboardati tramite il provisioning manuale -->
<template>
	<ValidationObserver ref="form">
		<form @submit.prevent="validate">
			<div>
				<h5 class="info-text" v-html="$t('wizard.step_2.title')" />

				<p class="info-text">
					{{ $t("wizard.step_2.content") }}
				</p>
			</div>
		</form>
	</ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);

export default {
	data() {
		return {
			template_selected: null,
			templates: [],
			flgShowTemplateVariables: false,
			template: null,
			variables: [],
		};
	},
	methods: {
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
	},
	mounted() {
		// Get data from API
		let userData = TokenService.getUser();
		evoapi.get("/customers/" + userData.customerId + "/templates").then((response) => {
			this.templates = response.data;
		});
	},
};
</script>
<style>
.evoseedAutocomplete {
	margin-top: -2px !important;
	margin-left: 10px;
	padding-bottom: 10px;
}
.md-no-border::after {
	height: 0px !important;
	margin-left: unset;
}
</style>
