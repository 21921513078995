function loadInitialState() {
	let storageSettings = {};

	try {
		storageSettings = JSON.parse(localStorage.getItem("settings"));
	} catch (error) {
		storageSettings = {};
	}

	return { ...settings, ...storageSettings };
}

export const settings = {
	namespaced: true,
	state: {
		locale: "it",
	},
	actions: {
		setLocale({ commit }, locale) {
			commit("setLocale", locale);
		},
		init({ commit }) {
			const settings = loadInitialState();
			//console.log("SE",settings)
			commit("setLocale", settings.locale);
		},
	},
	getters: {
		locale(state) {
			return state.locale;
		},
	},
	mutations: {
		setLocale(state, locale) {
			state.locale = locale;
			localStorage.setItem("settings", JSON.stringify({ ...state, locale }));
		},
	},
};
