<template>
	<div></div>
</template>
<script>
import Swal from "sweetalert2";
import eventbus from "@/common/eventbus";

export default {
	mounted() {
		Swal.fire({
			title: "Logging Out",
			text: this.$t("general.sure_to_proceed"),
			icon: "warning",
			showCancelButton: true,
			customClass: {
				confirmButton: "md-button md-success",
				cancelButton: "md-button md-danger btn-fill",
			},
			cancelButtonText: this.$t("general.cancel"),
			confirmButtonText: this.$t("general.ok"),
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				eventbus.dispatch("logout");
			} else {
				this.$router.go(-1);
			}
		});
	},
};
</script>

<style></style>
