<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<template slot="header">
					<h3 class="title">{{ $t("device.add") }}</h3>
					<h5 class="category">
						{{ $t("device.letstart") }}
					</h5>
				</template>

				<wizard-tab :before-change="() => validateStep('step1')">
					<template slot="label"> {{ $t("fields.name.label") }} </template>
					<first-step ref="step1" @on-validated="onStepValidated"></first-step>
				</wizard-tab>

				<wizard-tab :before-change="() => validateStep('step2')">
					<template slot="label"> {{ $t("fields.template.label") }} </template>
					<second-step ref="step2" @on-validated="wizardComplete"></second-step>
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import FirstStep from "./WizardSteps/FirstStep.vue";
import SecondStep from "./WizardSteps/SecondStep.vue";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

export default {
	data() {
		return {
			wizardModel: {},
			finishButtonText: "Aggiungi",
		};
	},
	components: {
		FirstStep,
		SecondStep,
		SimpleWizard,
		WizardTab,
	},
	methods: {
		validateStep(ref) {
			return this.$refs[ref].validate();
		},
		onStepValidated(validated, model) {
			this.wizardModel = { ...this.wizardModel, ...model };
		},
		wizardComplete() {
			let variables = [];
			for (var index in this.$refs.step2.variables) {
				variables.push({
					variable_name: index,
					variable_value: this.$refs.step2.variables[index],
				});
			}

			let req = {
				name: this.$refs.step1.name,
				device_groups: this.$refs.step1.deviceGroups,
				device_group_password:
					this.$refs.step1.deviceGroupPassword > 0 ? this.$refs.step1.deviceGroupPassword : null,
			};
			if (this.$refs.step2.template_id > 0) {
				req.template = {
					template_id: this.$refs.step2.template_id,
					variables: variables,
				};
			}

			let userData = TokenService.getUser();
			evoapi
				.post("/customers/" + userData.customerId + "/devices", req)
				.then((response) => {
					let commands = response.data.join("<br><br>");
					Swal.fire({
						title: this.$t("responses.wizard_success.title"),
						html: this.$t("responses.wizard_success.content", { commands }),
						confirmButtonText: this.$t("responses.wizard_success.ok"),
						showCloseButton: true,
						icon: "success",
						allowOutsideClick: false,
						backdrop: `
            rgba(0,0,123,0.4)
          `,
					}).then((result) => {
						if (result.isConfirmed) {
							if (window.clipboardData && window.clipboardData.setData) {
								// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
								return window.clipboardData.setData("Text", commands);
							} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
								var textarea = document.createElement("textarea");
								textarea.textContent = commands;
								textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
								document.body.appendChild(textarea);
								textarea.select();
								try {
									return document.execCommand("copy"); // Security exception may be thrown by some browsers.
								} catch (ex) {
								} finally {
									document.body.removeChild(textarea);
								}
							}
						}
					});
					this.$router.push({ name: "List Devices" });
				})
				.catch((error) => {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							html:
								this.$t("licence.activate.error.content") +
								"<code>" +
								error.response.data.error +
								"</code>",
							footer: this.$t("responses.error.footer"),
							showCloseButton: false,
							icon: "error",
							allowOutsideClick: false,
							backdrop: `rgba(150,0,0,0.4)`,
						});
					}
				});
		},
	},
};
</script>

<style>
.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}

.copy-paste-password {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: large;
	text-align: center;
}
</style>
