import { render, staticRenderFns } from "./Licenses.vue?vue&type=template&id=57cadc19&scoped=true&"
import script from "./Licenses.vue?vue&type=script&lang=js&"
export * from "./Licenses.vue?vue&type=script&lang=js&"
import style0 from "./Licenses.vue?vue&type=style&index=0&id=57cadc19&prod&lang=css&scoped=true&"
import style1 from "./Licenses.vue?vue&type=style&index=1&id=57cadc19&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57cadc19",
  null
  
)

export default component.exports