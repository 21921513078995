<template>
	<footer class="footer" v-if="hasPermission('footer:footer_list')">
		<div class="container">
			<nav>
				<ul>
					<li>
						<a
							href="https://youtube.com/playlist?list=PLhjqXDhYXF5RfCOd3Ny-erV6ogYtiopmJ&si=a_HbB99h730GcNvf"
							target="_blank">
							Video documentation
						</a>
					</li>
					|
					<li>
						<a href="https://github.com/evoseed/OptiWize/wiki" target="_blank"> Wiki documentation </a>
					</li>
					|
					<li>
						<a href="https://documenter.getpostman.com/view/19870664/2sAXqtchgM" target="_blank">
							API Postman collection
						</a>
					</li>
					|
					<li>
						<a href="https://github.com/evoseed/OptiWize/wiki/CHANGELOG-(version)" target="_blank">
							Changelog
						</a>
					</li>
					|
					<li>
						<a href="https://www.optiwize.cloud/" target="_blank"> OPTIWIZE </a>
					</li>
					|
					<li>
						<a href="https://evoseed.io/eng#team" target="_blank"> About Us </a>
					</li>
				</ul>
			</nav>
			<div class="copyright text-center">
				&copy; {{ new Date().getFullYear() }}
				<a href="https://evoseed.io" target="_blank">evoseed</a>
			</div>
		</div>
	</footer>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
	},
};
</script>
<style></style>
