<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100">
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(submit)">
					<md-card>
						<md-card-header class="md-card-header-icon md-card-header-green">
							<div class="card-text">
								<h4 class="title">{{ $t("template.edit") }}</h4>
							</div>
							<br /><br />
							<div class="text-right">
								<md-button class="md-info md-round md-small" @click.native="addNewVariable()">
									{{ $t("template.addVariable") }}
									<md-icon>add</md-icon>
								</md-button>
							</div>
						</md-card-header>

						<md-card-content>
							<ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("template.templateName") }}</label>
									<md-input v-model="template.title"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>

							<ValidationProvider name="description" v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("template.templateDescription") }}</label>
									<md-textarea v-model="template.description" md-autogrow ref="templateDescription">
									</md-textarea>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>

							<ValidationProvider
								name="from_version"
								:rules="{
									required: true,
									regex: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
								}"
								v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("template.validFrom") }}</label>
									<md-input v-model="template.from_version"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>

							<ValidationProvider
								name="to_version"
								:rules="{
									required: true,
									regex: /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
								}"
								v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
									<label>{{ $t("template.validTo") }}</label>
									<md-input v-model="template.to_version"> </md-input>

									<slide-y-down-transition>
										<md-icon class="error" v-show="failed">close</md-icon>
									</slide-y-down-transition>
									<slide-y-down-transition>
										<md-icon class="success" v-show="passed">done</md-icon>
									</slide-y-down-transition>
								</md-field>
							</ValidationProvider>

							<ValidationProvider name="text" rules="required" v-slot="{ passed, failed }">
								<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" maxlength="10">
									<label>{{ $t("template.templateText") }}</label>
									<md-textarea
										v-model="template.text"
										md-autogrow
										ref="templateText"
										style="
											background-color: black;
											color: white;
											-webkit-text-fill-color: white !important;
											font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
											padding: 1rem;
											font-size: small;
											max-height: 10000px;
										">
									</md-textarea>
								</md-field>
							</ValidationProvider>
						</md-card-content>

						<md-card-actions>
							<md-button type="submit" class="md-success">{{ $t("general.save") }}</md-button>
						</md-card-actions>
					</md-card>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import Swal from "sweetalert2";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";

extend("required", required);
extend("regex", regex);

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			template: {
				title: "",
				description: "",
				text: "",
				template_id: 0,
				from_version: "",
				to_version: "",
			},
		};
	},
	created() {
		this.template = this.$route.params.template;
	},
	methods: {
		submit() {
			let userData = TokenService.getUser();
			evoapi
				.put("/customers/" + userData.customerId + "/templates/" + this.template.template_id, {
					name: this.template.title,
					description: this.template.description,
					text: this.template.text,
					from_version: this.template.from_version,
					to_version: this.template.to_version,
				})
				.then((response) => {
					this.$router.push({ name: "List Templates" });
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		addNewVariable() {
			let ta = this.$refs.templateText.$el;
			let cpa = ta.selectionStart;
			let cpe = ta.selectionEnd;
			Swal.fire({
				text: this.$t("dialogs.add_variable_template.content"),
				input: "text",
				inputPlaceholder: this.$t("dialogs.add_variable_template.placeholder"),
				inputValidator: (value) => {
					if (!value) {
						return this.$t("dialogs.add_variable_template.validation");
					}
				},
				showCancelButton: true,
				confirmButtonText: this.$t("dialogs.add_variable_template.ok"),
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.isConfirmed) {
					// if(this.templateText.includes(result.value)) {
					//   Swal.fire({
					//     icon: 'error',
					//     title: 'Oops...',
					//     text: 'This variable name is already used',
					//   })
					// } else {
					this.template.text =
						this.template.text.substring(0, cpa) +
						"<<" +
						result.value +
						">>" +
						this.template.text.substring(cpe);
					// }
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
	border: none;
}
</style>
