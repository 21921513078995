class TokenService {
	getLocalRefreshToken() {
		return localStorage.getItem("refresh_token");
	}

	getLocalAccessToken() {
		return localStorage.getItem("access_token");
	}

	setLocalTokens(access, refresh) {
		localStorage.setItem("access_token", access);
		localStorage.setItem("refresh_token", refresh);
	}

	removeTokens() {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
	}

	getUser() {
		return JSON.parse(localStorage.getItem("userData"));
	}

	setUser(user) {
		localStorage.setItem("userData", JSON.stringify(user));
	}

	removeUser() {
		localStorage.removeItem("userData");
	}
}

export default new TokenService();
