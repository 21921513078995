<template>
	<div>
		<span class="aggregation-description" v-html="$t('wizard_sdwan.step_3.enable_aggegation')"></span>
		<br />
		<hr />
		<collapse :collapse="[$t('wizard_sdwan.step_2.show_ip_devices')]" icon="keyboard_arrow_down">
			<template slot="md-collapse-pane-1">
				<md-table>
					<md-table-row>
						<md-table-head>Address</md-table-head>
						<md-table-head>Network</md-table-head>
						<md-table-head>Interface</md-table-head>
					</md-table-row>

					<md-table-row v-for="address in addresses" :key="address.address">
						<md-table-cell>{{ address.address }}</md-table-cell>
						<md-table-cell>{{ address.network }}</md-table-cell>
						<md-table-cell>{{ address.interface }}</md-table-cell>
					</md-table-row>
				</md-table>
			</template>
		</collapse>
		<br />
		<div class="md-layout">
			<div class="md-layout-item md-size-50 ml-auto mt-4 md-small-size-100">
				<div class="checkbox"></div>
				<div class="table-gateway">
					<template>
						<table class="table bordered-table">
							<thead>
								<tr>
									<th colspan="4" style="background-color: #4caf50; color: white; padding: 10px">
										{{ $t("wizard_sdwan.step_3.gateway_list") }}
									</th>
								</tr>
								<tr>
									<th style="padding: 10px">{{ $t("wizard_sdwan.step_3.name") }}</th>
									<th style="padding: 10px">Pos</th>
									<th colspan="2" style="padding: 10px">{{ $t("wizard_sdwan.step_3.actions") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(gateway, i) in gateways" :key="i">
									<td>
										{{ gateway.gateway }}
									</td>
									<td>
										{{ gateway.options.local_preference }}
									</td>

									<td>
										<md-button
											class="md-icon-button md-list-action"
											@click.native="editGateway(gateway)">
											<md-icon>edit</md-icon>
										</md-button>
									</td>
									<td>
										<md-button
											class="md-icon-button md-list-action"
											@click.native="deleteGateway(gateway)">
											<md-icon>delete</md-icon>
										</md-button>
									</td>
								</tr>
							</tbody>
						</table>
					</template>
				</div>
			</div>
			<div class="md-layout-item md-size-50 ml-auto mt-4 md-small-size-100">
				<h5 class="info-text">
					{{ $t("wizard_sdwan.step_3.title") }}
					<br />
					<span v-html="$t('wizard_sdwan.step_3.subtitle')"></span>
					<br />
					<md-button class="md-raised md-success" @click.native="addGateway()" v-if="gateways.length < 4">
						<md-icon>add</md-icon> {{ $t("wizard_sdwan.step_3.add_gateway") }}
					</md-button>
				</h5>
				<collapse :multiple-active="false">
					<collapse-item name="1">
						<h5 slot="title" class="mb-0 btn btn-link w-100 text-primary text-left">
							{{ $t("wizard_sdwan.step_2.cta_order") }} <i class="ni ni-bold-down float-right pt-1"></i>
						</h5>
						<div></div>
					</collapse-item>
				</collapse>
				<gateway
					v-if="showGatewayEdit"
					:gatewayToEdit="gatewayToEdit"
					@gatewaySaved="gatewaySavedHandler"
					@hideGatewayEdit="hideGatewayEditHandler"
					:gatewayList="gatewaysProps"
					:provisionedSDWAN="true">
				</gateway>
			</div>
		</div>
	</div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Gateway from "../Gateway.vue";
import { Collapse } from "@/components";
// import draggable from "vuedraggable";
import Swal from "sweetalert2";

extend("required", required);

export default {
	components: {
		Gateway,
		Collapse,
	},
	// props: ["addresses", "gatewaysProps", "shieldAvailable", "gatewayShieldActive"],
	props: ["addresses", "gatewaysProps", "shieldAvailable"],
	data() {
		return {
			gateways: [],
			showGatewayEdit: false,
			gatewayToEdit: null,
			lastEdited: null,
			// gatewayShield: false,
			aggregationMode: false,
			// dialogVisible: false,
		};
	},
	watch: {
		// gatewayShieldActive: function () {
		// 	this.gatewayShield = this.gatewayShieldActive;
		// },
		template_id: function () {
			if (this.template_id > 0) {
				let userData = TokenService.getUser();
				evoapi.get("/customers/" + userData.customerId + "/templates/" + this.template_id).then((response) => {
					this.template = response.data;
					this.flgShowTemplateVariables = this.template.variables.length > 0;
				});
			} else {
				this.flgShowTemplateVariables = false;
			}
		},
	},
	methods: {
		onValidated(data) {
			//console.log("onValidated", data);
			this.aggregationMode = data.sdwanType === "1" ? true : false;
		},
		validate() {
			if (this.showGatewayEdit === false && this.gateways.length > 0) {
				this.$emit("on-validated", true);
			} else {
				// popup "no gateway defined"
				Swal.fire({
					icon: "error",
					title: this.$t("wizard_sdwan.step_3.swal_title"),
					text: this.$t("wizard_sdwan.step_3.swal_text"),
					footer: this.$t("responses.error.footer"),
				});
			}
		},
		deleteGateway(gateway) {
			this.gateways = this.gateways.filter((item) => item.gateway !== gateway.gateway);
		},
		addGateway() {
			this.gatewayToEdit = null;
			this.showGatewayEdit = true;
		},
		editGateway(gateway) {
			let gw = JSON.stringify(gateway);
			// logging gw
			this.gatewayToEdit = JSON.parse(gw);
			// converting gw.options.max_latency_up from microseconds to milliseconds
			this.gatewayToEdit.options.max_latency_up = gateway.options.max_latency_up / 1000;
			this.showGatewayEdit = true;
			this.lastEdited = JSON.parse(gw);
		},
		// gatewaySavedHandler(gateway) {
		// 	if (this.lastEdited != null) {
		// 		this.deleteGateway(this.lastEdited);
		// 	}
		// 	this.gateways.push(gateway);
		// 	this.showGatewayEdit = false;
		// 	this.lastEdited = null;
		// },
		gatewaySavedHandler(gateway) {
			if (this.lastEdited != null) {
				let index = this.gateways.findIndex((gw) => gw.gateway === this.lastEdited.gateway);
				if (index !== -1) {
					this.gateways.splice(index, 1, gateway);
				}
			} else {
				this.gateways.push(gateway);
			}
			// Ordinamento dell'array gateways in base a local_preference (dalla più alta alla più bassa)
			this.gateways.sort((a, b) => b.options.local_preference - a.options.local_preference);

			this.showGatewayEdit = false;
			this.lastEdited = null;
		},
		hideGatewayEditHandler() {
			this.showGatewayEdit = false;
			this.gatewayToEdit = null;
		},

		// emitShield() {
		// 	this.$emit("on-gateway-shield", this.gatewayShield);
		// },
	},
};
</script>
<style>
.aggregation-description {
	/* font-size: 12px; Rendi il testo più piccolo */
	text-align: justify; /* Giustifica il testo */
	text-justify: inter-word;
}
</style>

<style scoped>
.bordered-table {
	border: 1px solid #b0afaf;
	border-collapse: collapse;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/style";

.md-list {
	width: 320px;
	max-width: 100%;
	display: inline-block;
	vertical-align: top;
	border: 1px solid rgba(#000, 0.12);
}

.md-list-item-text {
	position: relative !important;
}
.checkbox-shield {
	margin-bottom: 0.25rem;

	.md-icon {
		margin-left: 0.25rem;
	}
}
.table-gateway {
	margin-top: 1rem;
}
</style>
<style scoped>
.handle {
	cursor: move;
}
td {
	padding: 15px; /* Aggiungo padding alle celle */
}
</style>
